//Camera.tsx
import { OrbitControls } from "@react-three/drei";
import { useThree, useFrame } from "@react-three/fiber";
import gsap from "gsap";
import { useEffect, useRef } from "react";
import { Vector3 } from "three";

// interface Point {
//   x: number;
//   y: number;
//   z: number;
// }


// DEMO TODO - revert the moveDuration, was 4.5
const CameraControls = ({ position, target, moveDuration = 6.5, cameraOrbitOn }) => {
  const {
    camera,
    gl: { domElement },
  } = useThree();

  const ref = useRef();

  let camTimer = 0
  let camTimerLimit = 500

  // console.log('---------- MOVING CAMERA....')
  // console.log(position);

  camera.up = new Vector3(0, 1, 0);

  //  const ExampleComponent = ({ sayName }) => {
  const cameraAnimate = () => {
    //function cameraAnimate() => {
    if (ref.current) {
      gsap.timeline().to(camera.position, {
        duration: moveDuration,
        repeat: 0,
        x: position.x,
        y: position.y,
        z: position.z,
        ease: "power3.inOut",
      });

      gsap.timeline().to(
        ref.current.target,
        {
          duration: moveDuration,
          repeat: 0,
          x: target.x,
          y: target.y,
          z: target.z,
          ease: "power3.inOut",
        },
      );
    }
  }

  useEffect(() => {
    cameraAnimate();
    // console.log('---------- MOVING CAMERA....')
    // console.log(position);
    // console.log(target);
  }, [target, position]);

  return (
    
    <OrbitControls
      ref={ref}
      args={[camera, domElement]}
      panSpeed={1}
      enablePan={cameraOrbitOn == 1 ? true : false}
      enableRotate={cameraOrbitOn == 1 ? true : false}
      enableZoom={cameraOrbitOn == 1 ? true : false}   
      maxDistance={1000} // 800 OG, now 1000
      minDistance={100}
      enableDamping={true}
      dampingFactor={.4}
      maxPolarAngle={Math.PI / 1.9}
      //minPolarAngle={Math.PI / 5}
      minAzimuthAngle={-Math.PI / 4.8}
      maxAzimuthAngle={Math.PI / 4.8}
    />
  );
};

export { CameraControls };
