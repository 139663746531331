import React, { useState, useEffect, useRef } from 'react';

const GridSelect = ({ viznodes, activeNode, setCardPositionXY }) => {
    const [hoveredCell, setHoveredCell] = useState(null);
    const [preloadedIcons, setPreloadedIcons] = useState({});

    const gridWidth = 71;
    const gridHeight = 41;
    const cellSize = 5;
    const cellColorBg = '#010101';
    const hoverColor = '#3c3c3c';
    const hoverColorAxis = '#3c3c3c';
    const colorAxisX = '#27272A';
    const colorAxisXMid = '#27272A';
    const colorAxisY = '#27272A';

    const canvasRef = useRef(null);

    // Define SVG URLs for each type
    const svgIconUrls = {
        Image: '/media/icon/noun-image-2761478-FFFFFF.svg',
        Person: '/media/icon/noun-person-5763652-FFFFFF.svg',
        'Super Card': '/media/icon/noun-membership-2397537-FFFFFF.svg',
        Gallery: '/media/icon/noun-devices-51939-FFFFFF.svg',
        Web: '/media/icon/noun-web-5827943-FFFFFF.svg',
        Text: '/media/icon/noun-text-5443090-FFFFFF.svg',
        Link: '/media/icon/noun-link-162420-FFFFFF.svg',
        // Add more types and their corresponding SVG URLs
    };

    useEffect(() => {
        // Preload icons
        const loadIcons = async () => {
            const loadedIcons = {};
            for (const [key, url] of Object.entries(svgIconUrls)) {
                const img = new Image();
                img.src = url;
                await new Promise((resolve) => {
                    img.onload = resolve;
                });
                loadedIcons[key] = img;
            }
            setPreloadedIcons(loadedIcons);
        };
        loadIcons();
    }, []);

    const getColorByType = (type) => {
        const colorLookup = {
            Image: '#9FC858',
            Person: '#DFA550',
            'Super Card': '#6EB3A6',
            Gallery: '#945CE7',
            Web: '#B5647C',
            Text: '#E2C252',
            Link: '#6466E1',
        };
        return colorLookup[type] || cellColorBg;
    };

    const remapNumberY = (number) => {
        const min1 = -20;
        const max1 = 50;
        const min2 = 50;
        const max2 = -20;
        const relativePosition = (number - min1) / (max1 - min1);
        return Math.round((relativePosition * (max2 - min2)) + min2) + 2;
    };

    const remapNumberX = (number) => (number - 35) * -1;

    const handleCellClick = (x, y) => {
        setCardPositionXY({ x: remapNumberX(x), y: remapNumberY(y) });
    };

    const handleCellHover = (x, y) => {
        setHoveredCell({ x, y });
    };

    const drawRoundedRect = (ctx, x, y, width, height, radius) => {
        ctx.beginPath();
        ctx.imageSmoothingEnabled = true;
        ctx.imaageSmoothingQuality = 'high';
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.closePath();
    };

    useEffect(() => {
        if (!canvasRef.current || Object.keys(preloadedIcons).length === 0) return;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d', { alpha: false });

        const drawGrid = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            for (let x = 0; x < gridWidth; x++) {
                for (let y = gridHeight; y > 0; y--) {
                    const cellX = x * cellSize;
                    const cellY = y * cellSize;
                    let cellColor = cellColorBg;

                    let isHover = false

                    if (x === Math.round(gridWidth / 2) - 1 || 
                        x === Math.round(gridWidth / 4) - 1 ||
                        x === Math.round(gridWidth / 2) + Math.round(gridWidth / 4)) {
                        cellColor = colorAxisX;
                    }

                    if (y === 12 || y === 32) {
                        cellColor = colorAxisY;
                    }

                    if (y === 22) {
                        cellColor = colorAxisXMid;
                    }

                    if (hoveredCell && (hoveredCell.x === x || hoveredCell.y === Math.round(y) - 1)) {
                        cellColor = hoverColorAxis;
                        isHover = true
                    }

                    ctx.fillStyle = cellColor;
                    ctx.fillRect(cellX, cellY, cellSize - 2, cellSize - 2);
                }
            }
        };

        const drawNodes = () => {
            viznodes.forEach((node) => {
                if (node.name === '3D Item') return;

                const cellX = parseInt(node.pos.x * -1) * cellSize;
                const cellY = parseInt(remapNumberY(node.pos.y)) * cellSize;

                let cellSizeActive = cellSize * 2;
                let cellYActive = cellY - 2;
                //let cellXActive = (cellX + 30 * cellSize) - 2;
                let cellXActive = (cellX + 30 * cellSize) + 20;

                const icon = preloadedIcons[node.name] || preloadedIcons['Default'];

                // Determine background color
                let bgColor = '#27272A' //getColorByType(node.name);
                if (hoveredCell) {
                    if (hoveredCell.y === remapNumberY(node.pos.y) || hoveredCell.x === remapNumberX(node.pos.x)) {
                        bgColor = '#52525B';
                    }
                }


                const spacing = 3;
                const iconSize = 2;


                // Draw background
                ctx.fillStyle = bgColor;
                drawRoundedRect(ctx, (cellXActive - spacing * 2) -1 , cellYActive - spacing * 2, cellSizeActive * spacing, cellSizeActive * spacing, 4);
                ctx.fill();

                ctx.strokeStyle = '#333';
                ctx.lineWidth = 1;
                drawRoundedRect(ctx, (cellXActive - spacing * 2) -1 , cellYActive - spacing * 2, cellSizeActive * spacing, cellSizeActive * spacing, 4);
                ctx.stroke();                


                // Draw icon
                if (icon) {
                    ctx.save();
                    drawRoundedRect(ctx, cellXActive - 2, cellYActive - 2, cellSizeActive * 2, cellSizeActive * 2, 4);
                    ctx.clip();
                    ctx.drawImage(icon, cellXActive - 2, cellYActive - 2, cellSizeActive * iconSize, cellSizeActive * iconSize);
                    ctx.restore();
                }

                // Draw border for active node
                if (node.guid === activeNode) {
                    // ctx.save();
                    // drawRoundedRect(ctx, cellXActive - spacing * 2, cellYActive - spacing * 2, cellSizeActive * spacing, cellSizeActive * spacing, 4);
                    // ctx.clip();
                    // ctx.drawImage(icon, cellXActive - spacing * 2, cellYActive - spacing * 2, cellSizeActive * spacing, cellSizeActive * spacing);
                    // ctx.restore();
                
                    ctx.strokeStyle = '#D4D4D8';
                    ctx.lineWidth = 1;
                    drawRoundedRect(ctx, (cellXActive - spacing * 2) -1 , cellYActive - spacing * 2, cellSizeActive * spacing, cellSizeActive * spacing, 4);
                    ctx.stroke();
                }
            });
        };

        drawGrid();
        drawNodes();
    }, [viznodes, activeNode, hoveredCell, preloadedIcons]);

    return (
        <canvas
            ref={canvasRef}
            width={gridWidth * cellSize}
            className='mx-auto rounded-md mt-2 mb-2'
            height={gridHeight * cellSize}
            onClick={(e) => {
                const rect = canvasRef.current.getBoundingClientRect();
                const x = Math.floor((e.clientX - rect.left) / cellSize);
                const y = Math.floor((e.clientY - rect.top) / cellSize);
                handleCellClick(x, y);
            }}
            onMouseMove={(e) => {
                const rect = canvasRef.current.getBoundingClientRect();
                const x = Math.floor((e.clientX - rect.left) / cellSize);
                const y = Math.floor((e.clientY - rect.top) / cellSize);
                handleCellHover(x, y);
            }}
            onMouseLeave={() => {
                setHoveredCell(null);
            }}
        />
    );
};

export default GridSelect;

// import React, { useState, useEffect, useRef } from 'react';

// const GridSelect = ({ viznodes, activeNode, setCardPositionXY }) => {
//     const [hoveredCell, setHoveredCell] = useState(null);
//     const [preloadedIcons, setPreloadedIcons] = useState({});

//     const gridWidth = 71;
//     const gridHeight = 41;
//     const cellSize = 5;
//     const cellColorBg = '#010101';
//     const hoverColor = '#3c3c3c';
//     const hoverColorAxis = '#3c3c3c';
//     const colorAxisX = '#18181B';
//     const colorAxisXMid = '#18181B';
//     const colorAxisY = '#18181B';

//     const canvasRef = useRef(null);

//     // Define SVG URLs for each type
//     const svgIconUrls = {
//         Image: '/media/icon/noun-image-2761478-FFFFFF.svg',
//         Person: '/media/icon/noun-person-5763652-FFFFFF.svg',
//         'Super Card': '/media/icon/noun-membership-2397537-FFFFFF.svg',
//         Gallery: '/media/icon/noun-devices-51939-FFFFFF.svg',
//         Web: '/media/icon/noun-web-5827943-FFFFFF.svg',
//         Text: '/media/icon/noun-text-5443090-FFFFFF.svg',
//         Link: '/media/icon/noun-link-162420-FFFFFF.svg',
//         // Add more types and their corresponding SVG URLs
//     };

//     useEffect(() => {
//         // Preload icons
//         const loadIcons = async () => {
//             const loadedIcons = {};
//             for (const [key, url] of Object.entries(svgIconUrls)) {
//                 const img = new Image();
//                 img.src = url;
//                 await new Promise((resolve) => {
//                     img.onload = resolve;
//                 });
//                 loadedIcons[key] = img;
//             }
//             setPreloadedIcons(loadedIcons);
//         };
//         loadIcons();
//     }, []);

//     const getColorByType = (type) => {
//         const colorLookup = {
//             Image: '#9FC858',
//             Person: '#DFA550',
//             'Super Card': '#6EB3A6',
//             Gallery: '#945CE7',
//             Web: '#B5647C',
//             Text: '#E2C252',
//             Link: '#6466E1',
//         };
//         return colorLookup[type] || cellColorBg;
//     };

//     const remapNumberY = (number) => {
//         const min1 = -20;
//         const max1 = 50;
//         const min2 = 50;
//         const max2 = -20;
//         const relativePosition = (number - min1) / (max1 - min1);
//         return Math.round((relativePosition * (max2 - min2)) + min2);
//     };

//     const remapNumberX = (number) => (number - 30) * -1;

//     const handleCellClick = (x, y) => {
//         setCardPositionXY({ x: remapNumberX(x), y: remapNumberY(y) });
//     };

//     const handleCellHover = (x, y) => {
//         setHoveredCell({ x, y });
//     };

//     useEffect(() => {
//         if (!canvasRef.current || Object.keys(preloadedIcons).length === 0) return;

//         const canvas = canvasRef.current;
//         const ctx = canvas.getContext('2d', { alpha: false });

//         const drawGrid = () => {
//             ctx.clearRect(0, 0, canvas.width, canvas.height);

//             for (let x = 0; x < gridWidth; x++) {
//                 for (let y = gridHeight; y > 0; y--) {
//                     const cellX = x * cellSize;
//                     const cellY = y * cellSize;
//                     let cellColor = cellColorBg;

//                     if (x === Math.round(gridWidth / 2) - 1 || 
//                         x === Math.round(gridWidth / 4) - 1 ||
//                         x === Math.round(gridWidth / 2) + Math.round(gridWidth / 4)) {
//                         cellColor = colorAxisX;
//                     }

//                     if (y === 12 || y === 32) {
//                         cellColor = colorAxisY;
//                     }

//                     if (y === 22) {
//                         cellColor = colorAxisXMid;
//                     }

//                     if (hoveredCell && (hoveredCell.x === x || hoveredCell.y === Math.round(y))) {
//                         cellColor = hoverColorAxis;
//                     }

//                     ctx.fillStyle = cellColor;
//                     ctx.fillRect(cellX, cellY, cellSize, cellSize);
//                 }
//             }
//         };

//         const drawNodes = () => {
//             viznodes.forEach((node) => {
//                 if (node.name === '3D Item') return;

//                 const cellX = parseInt(node.pos.x * -1) * cellSize;
//                 const cellY = parseInt(remapNumberY(node.pos.y)) * cellSize;

//                 let cellSizeActive = cellSize * 2;
//                 let cellYActive = cellY - 2;
//                 let cellXActive = (cellX + 30 * cellSize) - 2;

//                 const icon = preloadedIcons[node.name] || preloadedIcons['Default'];

//                 // Determine background color
//                 let bgColor = 'black' //getColorByType(node.name);
//                 if (hoveredCell) {
//                     if (hoveredCell.y === remapNumberY(node.pos.y) || hoveredCell.x === remapNumberX(node.pos.x)) {
//                         bgColor = '#A1A1AA';
//                     }
//                 }

//                 // Draw background
//                 ctx.fillStyle = bgColor;
//                 ctx.fillRect(cellXActive - 2, cellYActive - 2, cellSizeActive * 2, cellSizeActive * 2);

//                 const spacing = 2.5;

//                 // Draw icon
//                 if (icon) {
//                     ctx.drawImage(icon, cellXActive - 2, cellYActive - 2, cellSizeActive * 2, cellSizeActive * 2);
//                 }

//                 // Draw border for active node
//                 if (node.guid === activeNode) {
//                     ctx.drawImage(icon, cellXActive - spacing * 2, cellYActive -  spacing * 2, cellSizeActive *spacing, cellSizeActive * spacing);
                
//                     ctx.strokeStyle = '#A1A1AA';
//                     ctx.lineWidth = 5;
//                     ctx.strokeRect(cellXActive - spacing * 2, cellYActive -  spacing * 2, cellSizeActive *spacing, cellSizeActive * spacing);
//                 }
//                 else {
//                     // ctx.strokeStyle = '#18181B';
//                     // ctx.lineWidth = 5;
//                     // ctx.strokeRect(cellXActive - 2, cellYActive - 2, cellSizeActive * 2, cellSizeActive * 2);
//                 }
//             });
//         };

//         drawGrid();
//         drawNodes();
//     }, [viznodes, activeNode, hoveredCell, preloadedIcons]);

//     return (
//         <canvas
//             ref={canvasRef}
//             width={gridWidth * cellSize}
//             className='mx-auto rounded-md mt-2 mb-2'
//             height={gridHeight * cellSize}
//             onClick={(e) => {
//                 const rect = canvasRef.current.getBoundingClientRect();
//                 const x = Math.floor((e.clientX - rect.left) / cellSize);
//                 const y = Math.floor((e.clientY - rect.top) / cellSize);
//                 handleCellClick(x, y);
//             }}
//             onMouseMove={(e) => {
//                 const rect = canvasRef.current.getBoundingClientRect();
//                 const x = Math.floor((e.clientX - rect.left) / cellSize);
//                 const y = Math.floor((e.clientY - rect.top) / cellSize);
//                 handleCellHover(x, y);
//             }}
//             onMouseLeave={() => {
//                 setHoveredCell(null);
//             }}
//         />
//     );
// };

// export default GridSelect;

// import React, { useState } from 'react';

// const GridSelect = ({ viznodes, activeNode, setCardPositionXY }) => {
//     const [hoveredCell, setHoveredCell] = useState(null);

//     const [isAnimating, setIsAnimating] = useState(false);

//     const gridWidth = 71;
//     const gridHeight = 41;
//     const cellSize = 5;
//     const cellColor = '#111';
//     const activeNodeColor = 'white';
//     const hoverColor = '#3c3c3c';
//     const hoverColorAxis = '#3c3c3c';
//     const colorAxisX = '#222';
//     const colorAxisXMid = '#333';
//     const colorAxisY = '#222';

//     const cellColorBg = '#111' //'#15171B';

//     const canvasRef = React.useRef(null);

//     // Define SVG URLs for each type
//     const svgIconUrls = {
//         Image: '/media/icon/noun-image-2761478-FFFFFF.svg',
//         Person: '/media/icon/noun-person-5763652-FFFFFF.svg',
//         'Super Card': '/media/icon/noun-membership-2397537-FFFFFF.svg',
//         Gallery: '/media/icon/noun-devices-51939-FFFFFF.svg',
//         Web: '/media/icon/noun-web-5827943-FFFFFF.svg',
//         Text: '/media/icon/noun-text-5443090-FFFFFF.svg',
//         Link: '/media/icon/noun-link-162420-FFFFFF.svg',
//         // Add more types and their corresponding SVG URLs
//     };

//     const getColorByType = (type) => {
//         // Define your color lookup table here
//         const colorLookup = {
//             Image: '#9FC858',
//             Person: '#DFA550',
//             'Super Card': '#6EB3A6',
//             Gallery: '#945CE7',
//             Web: '#B5647C',
//             Text: '#E2C252',
//             Link: '#6466E1',
//             // Add more types and colors as needed
//         };

//         return colorLookup[type] || cellColorBg;
//     };

//     // const checkOverlap = (x, y, rectX, rectY, rectWidth, rectHeight) => {
//     //     console.log('checking')
//     //     if (x >= rectX && x <= rectX + rectWidth && y >= rectY && y <= rectY + rectHeight) {
//     //         return true;
//     //     }
//     //     return false;
//     // };

//     const remapNumberY = (number) => {
//         const min1 = -20;
//         const max1 = 50;
//         const min2 = 50;
//         const max2 = -20;

//         // Check if the number is within the range
//         if (number < min1 || number > max1) {
//             //throw new Error('Number is out of range');
//         }

//         const relativePosition = (number - min1) / (max1 - min1);
//         const remappedNumber = Math.round((relativePosition * (max2 - min2)) + min2);
//         return remappedNumber;
//     };

//     const remapNumberX = (number) => {
//         return (number - 30) * -1;
//     };

//     const handleCellClick = (x, y) => {
//         // console.log('x', remapNumberX(x))
//         // console.log('y', remapNumberY(y))
//         setCardPositionXY({ x: remapNumberX(x), y: remapNumberY(y) })
//     };

//     const handleCellHover = (x, y) => {
//         setHoveredCell({ x, y });
//     };

//     React.useEffect(() => {
//         const canvas = canvasRef.current;
//         const ctx = canvas.getContext('2d', { alpha: false });

//         // let animationFrameId;

//         // const render = () => {
//         //     if (isAnimating) {
//         ctx.clearRect(0, 0, canvas.width, canvas.height);

//         // Draw the grid
//         for (let x = 0; x < gridWidth; x++) {
//             //console.log('x', x)
//             //for (let y = 0; y < gridHeight ; y++) {
//             for (let y = gridHeight; y > 0; y--) {
//                 const cellX = x * cellSize;
//                 const cellY = y * cellSize;
//                 let strokeStyle = '#000';

//                 // Set the cell color
//                 let cellColor = getColorByType('default');
//                 if (hoveredCell && hoveredCell.x === x && hoveredCell.y === y) {
//                     cellColor = hoverColor;
//                     //strokeStyle = 'white';
//                 } else {
//                     //console.log('viznodes', viznodes)
//                     viznodes.forEach((node) => {
//                         if (node.pos.x === x.toString() && node.pos.y === y.toString()) {
//                             cellColor = getColorByType(node.name);
//                         }
//                     });
//                 }

//                 // console.log('HEIGHT AXIS', Math.round(gridHeight /2))
//                 // console.log('WIDTH AXIS', Math.round(gridWidth /2))

//                 if (
//                     x == Math.round(gridWidth / 2) - 1 ||
//                     x == Math.round(gridWidth / 4) - 1 ||
//                     x == Math.round(gridWidth / 2) + Math.round(gridWidth / 4)
//                     //y == 22 || y == 12|| y == 32 
//                 ) {
//                     cellColor = colorAxisX;
//                 }

//                 if (
//                     y == 12 || y == 32
//                 ) {
//                     cellColor = colorAxisY;
//                 }

//                 if (y == 22) {
//                     cellColor = colorAxisXMid;
//                 }

//                 if (hoveredCell && hoveredCell.x === x || hoveredCell && hoveredCell.y === Math.round(y)) {
//                     cellColor = hoverColorAxis;
//                 }

//                 // Draw the cell
//                 ctx.fillStyle = cellColor;
//                 ctx.fillRect(cellX, cellY, cellSize, cellSize);
//                 ctx.strokeStyle = strokeStyle;
//                 ctx.lineWidth = 2
//                 ctx.strokeRect(cellX, cellY, cellSize, cellSize);
//             }
//         }

//         // Draw the active node on top
//         viznodes.forEach((node) => {
//             //if (node.guid === activeNode) {

//             if (node.name == '3D Item') {
//                 return
//             }

//             let cellColor = 'black'

//             const cellX = parseInt(node.pos.x * -1) * cellSize;
//             const cellY = parseInt(remapNumberY(node.pos.y)) * cellSize;
//             //let cellColor = getColorByType(node.name);

//             if (hoveredCell) {
//                 if (hoveredCell.y === remapNumberY(node.pos.y)) {
//                     cellColor = 'white' //hoverColorAxis;
//                 }
//             }

//             if (hoveredCell) {
//                 if (hoveredCell.x === remapNumberX(node.pos.x)) {
//                     cellColor = 'white' //hoverColorAxis;
//                     //alert('x')
//                 }
//             }

//             let cellSizeActive = cellSize * 2;
//             let cellYActive = cellY - 2 // - ((cellSizeActive + 2) / 2);
//             let cellXActive = (cellX + 30 * cellSize) - 2

//             if (node.guid === activeNode) {
//                 //cellSizeActive = cellSize * 3;
//                 //cellColor = activeNodeColor;

//                 ctx.beginPath();
//                 ctx.arc(cellXActive + 4, cellYActive + 4, 6, 0, 2 * Math.PI);
//                 ctx.fillStyle = cellColorBg;
//                 ctx.fill();
//                 ctx.lineWidth = 4;
//                 ctx.strokeStyle = cellColor;
//                 ctx.stroke();
//                 return
//             }

//             // ctx.fillStyle = cellColor //getColorByType(node.type);
//             // ctx.fillRect(cellXActive, cellYActive, cellSizeActive, cellSizeActive);
//             // ctx.strokeStyle = cellColor //getColorByType(node.type);
//             // ctx.lineWidth = 2
//             // ctx.strokeRect(cellXActive, cellYActive, cellSizeActive, cellSizeActive);
//             //}

//             const iconUrl = svgIconUrls[node.name] || svgIconUrls['Default'];
            
//             const img = new Image();
//             img.onload = function () {
//                 ctx.drawImage(img, cellXActive, cellYActive, cellSizeActive * 2, cellSizeActive * 2);

//                 if (node.guid === activeNode) {
//                     ctx.strokeStyle = 'white';
//                     ctx.lineWidth = 5;
//                     ctx.strokeRect(cellXActive, cellYActive, cellSizeActive * 2, cellSizeActive * 2);
//                 }
//             };

//             //cellColor = 'white';
//             ctx.fillStyle = cellColor //getColorByType(node.type);
//             ctx.fillRect(cellXActive, cellYActive, cellSizeActive * 2, cellSizeActive * 2);
//             ctx.strokeStyle = cellColor //getColorByType(node.type);
//             ctx.lineWidth = 5
//             ctx.strokeRect(cellXActive, cellYActive, cellSizeActive * 2, cellSizeActive * 2);

//             img.src = iconUrl;

//         });

//         //}
//         // };

//         // render();

//         // return () => {
//         //     cancelAnimationFrame(animationFrameId);
//         // };

//     }, [viznodes, activeNode, hoveredCell, isAnimating]);


//     const startAnimating = () => {
//         setIsAnimating(true);
//     };

//     const stopAnimating = () => {
//         //setIsAnimating(false);
//         setIsAnimating(true);
//         setHoveredCell(null);
//     };

//     return (
//         <canvas
//             ref={canvasRef}
//             width={gridWidth * cellSize}
//             //style={{ border: '3px solid #000', borderRadius: '8px'}}
//             className='mx-auto   rounded-md mt-2 mb-2'
//             height={gridHeight * cellSize}
//             onClick={(e) => {
//                 startAnimating()
//                 const rect = canvasRef.current.getBoundingClientRect();
//                 const x = Math.floor((e.clientX - rect.left) / cellSize);
//                 const y = Math.floor((e.clientY - rect.top) / cellSize);
//                 handleCellClick(x, y);
//             }}
//             onMouseMove={(e) => {
//                 startAnimating()
//                 const rect = canvasRef.current.getBoundingClientRect();
//                 const x = Math.floor((e.clientX - rect.left) / cellSize);
//                 const y = Math.floor((e.clientY - rect.top) / cellSize);
//                 handleCellHover(x, y);
//             }}
//             //onMouseMove={startAnimating}
//             //onClick={startAnimating}
//             onTouchStart={startAnimating}
//             onMouseLeave={stopAnimating}
//         />

//     );
// };

// export default GridSelect;