import React from 'react';
import { Route, Routes } from 'react-router-dom';
//import Navbar from './components/Navbar';
import Protected from './components/Protected';
import { AuthContextProvider } from './context/AuthContext';
//import Account from './pages/Account';
import Signin from './pages/Signin';
//import AppMainH from './AppMainH'
//import SceneBuilder from './components/SceneBuilder';
//import Story from './components/Story';
import AdminNav from './components/AdminNav';

// Story Editor
import AppStoryPlayer from './AppStoryPlayer';
//import AppStoryPublicPlayer from './AppStoryPublicPlayer';

// Pages
import Home from './pages/Home';
import Stories from './pages/Stories';
import Broadcast from './pages/Broadcast';
import Insights from './pages/Insights';
import Messages from './pages/Messages';
//import PublicHome from './pages/PublicHome';

//import PublicHomeWIP from './pages/PublicHomeWIP';

import NotFound from './pages/NotFound';
import PublicTemplate from './pages/PublicTemplate';
import PublicTest from './pages/PublicTest';
import Training from './pages/Training';
import PublicHomeLive from './pages/PublicHomeLive';
import PublicAbout from './pages/PublicAbout';
//import PublicTest from './pages/PublicTest';
import PublicPromo from './pages/PublicPromo';
import PublicPrivacy from './pages/PublicPrivacy';
import PublicTerms from './pages/PublicTerms';
import PublicContact from './pages/PublicContact';
import PublicBook from './pages/PublicBook';
import Signup from './pages/Signup';
import SampleApp from './pages/SampleApp';
import Folder from './pages/Folder';
import PublicSalesforce from './pages/PublicSalesforce';
import Admin from './pages/Admin';
import PublicHomeV2 from './pages/PublicHomeV2';
import Login from './pages/Login';
import Share from './pages/Share';
import PublicAI from './pages/PublicAI';

import Scene from './pages/Scene';

//import { AppRegistry, StyleSheet, Text, View } from 'react-native';

function App() {

  // AppRegistry.registerComponent('App', () => App);
  // AppRegistry.runApplication('App', { rootTag: document.getElementById('react-root') });

  return (
    <>
      <AuthContextProvider>
        <Routes>

          {/* Check to make sure it is a 'public' presentation */}
          {/* use pwd wrapper... */}
          <Route path="/play/:storyId" element={<AppStoryPlayer />} />
          {/* <Route path="/share/:storyId" element={<AppStoryPlayer />} /> */}
          <Route path="/share/:storyId/v/:shareCode" element={<AppStoryPlayer />} />

          {/* <Route path="/app/stories/:storyId/play" element={<AppStoryPlayer />} /> */}
       
          {/* Do not use navigation on side - standalone page */}
          <Route path="/app/stories/:storyId/edit/:sceneIndex" element={<Protected><AppStoryPlayer /></Protected>} />

          <Route path='/app' element={<Protected><AdminNav /></Protected>}>
            

            {/* <Route path='stories/public' element={<Insights />} />
            <Route path='stories/my' element={<Insights />} />
            <Route path='stories/templates' element={<Insights />} /> */}


            <Route path='home' element={<Home />} />
            {/* <Route path='/app/account' element={<Home />}/> */}
            {/* <Route path='stories/:storyId/edit/:sceneIndex' element={<AppStoryEditor />} /> */}
            <Route path='stories' element={<Stories />} />
            <Route path='admin' element={<Admin />} />
            {/* <Route path="stories/:storyId" element={<Story />} /> */}
            <Route path='broadcast' element={<Broadcast />} />
            <Route path='share' element={<Share />} />
            <Route path='insights' element={<Insights />} />
            <Route path='messages' element={<Messages />} />
            <Route path='folder' element={<Folder />} />
            <Route path='training' element={<Training />} />
            <Route path='scene' element={<Scene />} />
            {/* <Route path='*' element={<NotFound />} /> */}
          </Route>

          <Route path='/appsample' element={<SampleApp />} />

          <Route path='/' element={<PublicTemplate />}>
            <Route index element={<PublicHomeV2 />} />
            <Route path='login' element={<Login />} />
            <Route path='signup' element={<Signup />} />
            <Route path='about' element={<PublicAbout />} />
            {/* <Route path='salesforce-innovator' element={<PublicSalesforce />} /> */}
            <Route path='privacy' element={<PublicPrivacy />} />
            <Route path='contact' element={<PublicContact />} />
            <Route path='terms' element={<PublicTerms />} />
            <Route path='hello' element={<PublicPromo />} />
            <Route path='remarkable' element={<PublicAI />} />
            <Route path='signin' element={<Signin />} />
            <Route path='*' element={<NotFound />} />
          </Route>

          <Route path='/innovator' element={<PublicTemplate />} >
            <Route path='salesforce' element={<PublicSalesforce />} />
          </Route>


          {/* <Route path='*' element={<NotFound />} /> */}

        </Routes>
      </AuthContextProvider>
    </>
  );
}

export default App;
