import React, { useRef, useState, useContext, useEffect, createContext, Fragment } from "react";
import { db } from '../firebase'
import {
    query,
    collection,
    onSnapshot,
    updateDoc,
    doc,
    addDoc,
    deleteDoc,
    setDoc,
} from 'firebase/firestore'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from "react-router-dom";
import { SessionContext, SceneContext, StoryContext } from '/AppStoryPlayer'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

const ConfigLongText = ({ contentItem }) => {

    const [valueLongText, setValueLongText] = useState();
    const [cardLayout, setCardLayout] = useState()
    const { storyId } = useParams()
    useEffect(() => {
        if (contentItem) {
            setValueLongText(contentItem.content)
            setCardLayout(contentItem.position || { id: 1, name: 'Lower Right' })
        }
    }, [])

    const [storyData, setStoryData] = useContext(StoryContext)
    const [sceneData, setSceneData] = useContext(SceneContext)
    const [sessionData, setSessionData] = useContext(SessionContext)
    
    const layoutStyles = [
        { id: 1, name: 'Lower Right' },
        { id: 2, name: 'Lower Left' },
        { id: 3, name: 'Upper Right' },
        { id: 4, name: 'Upper Left' },
        { id: 5, name: 'Center' },
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const updateContent = async (e) => {
        console.log('Update Content...')
        console.log(valueLongText)
        console.log(contentItem)

        let cloneVizActive = []
        cloneVizActive = [...sceneData[sessionData.activeIndex].viznodes]

        cloneVizActive.forEach(item => {
            console.log(item)

            if ((contentItem.guid == item.guid)) {

                // TODO - let user place overlay...
                contentItem.position = cardLayout //..... (use TL, TR, Center, BL, BR)
                contentItem.content = valueLongText
            }
        })

        let activeSceneId = sceneData[sessionData.activeIndex].id
        const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId)

        updateDoc(sceneRef, {
            viznodes: cloneVizActive
        })
    }

    const modules = {
        toolbar: [
          // [{ header: [1, 2, 3, 4, 5, 6, false] }],
          // ["bold"],
          // [{ list: "ordered" }, { list: "bullet" }],
          // ["link"],
    
          ['bold', 'italic', 'underline'],
          [{ align: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }],
          //[{ size: ['small', false, 'large', 'huge'] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          //['link', 'image', 'video'],
          ['link'],
          [{ color: [] }, { background: [] }],
    
          ['clean'],
    
        ]
      }

    return (
        // <form onSubmit={(e) => {
        //     e.preventDefault()
        // }}>
        <div className="mr-1">

            {contentItem.id == 8 ?
                <div className="mt-4">
                    <div className='flex w-full'>
                        <div className='flex w-1/4 text-gray-200 pt-2 pl-2 text-sm'>Position</div>
                        <div className='flex-auto'>
                            <Listbox value={cardLayout} onChange={setCardLayout}>
                                {({ open }) => (
                                    <>
                                        {/* <Listbox.Label className="block leading-6 text-gray-300">Animation</Listbox.Label> */}
                                        <div className="relative  ">
                                            <Listbox.Button className="relative w-full cursor-default rounded-md 
                                                    bg-white/5 hover:bg-white/10
                                                     py-2 pl-3 pr-10 text-left text-gray-200 shadow-sm ring-1 ring-inset ring-gray-900 focus:outline-none  sm:text-sm sm:leading-6
                                                
                                                ">
                                                <span className="block truncate">{cardLayout} &nbsp;</span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                </span>
                                            </Listbox.Button>

                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-zinc-800 py-1 text-base  ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {layoutStyles.map((person) => (
                                                        <Listbox.Option
                                                            key={person.id}
                                                            className={({ active }) =>
                                                                classNames(
                                                                    active ? 'bg-sky-500 text-white' : 'text-gray-300',
                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                )
                                                            }
                                                            value={person.name}
                                                        >
                                                            {({ cardLayout, active }) => (
                                                                <>
                                                                    <span className={classNames(cardLayout ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                        {person.name}
                                                                    </span>

                                                                    {cardLayout ? (
                                                                        <span
                                                                            className={classNames(
                                                                                active ? 'text-white' : 'text-sky-600',
                                                                                'absolute bg-zinc-800 inset-y-0 right-0 flex items-center pr-4'
                                                                            )}
                                                                        >
                                                                        </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    ))}
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </>
                                )}
                            </Listbox>

                        </div>

                    </div>

                </div>
                : null}




            <div className="mt-4 h-200">
                <ReactQuill theme="snow" modules={modules} value={valueLongText} onChange={setValueLongText} />
            </div>
            <div className="sm:col-span-1 mt-6">
                <button className='w-full justify-center rounded-md bg-sky-500 px-3 py-1
                    text-l font-base text-white shadow-sm bg-sky-500 hover:bg-sky-400 focus-visible:outline
                    focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' onClick={() => updateContent()}>Update</button>
            </div>
        </div>
        // </form>
    )
}

export default ConfigLongText