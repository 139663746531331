import { React, useState, useEffect, useRef } from 'react';
import '/Style.css';
import '/StyleAnimations.css';
import FormBook from './FormBook';
import CompSiteNav from './CompSiteNav';
import { SwishSpinner } from "react-spinners-kit";
import Markdown from 'react-markdown'
import CompRegister from './CompRegister';
import CompModelAI from '../components/CompModelAI';
//import ReactQuill from 'react-quill';
import Highlighter from "react-highlight-words";
import axios from 'axios'; // Import Axios

import CompVideoCapture from '../components/CompVideoCapture.jsx';

// Create a custom Axios instance with default configs
const api = axios.create({
    // withCredentials: true, // This is important for cookies, authorization headers with HTTPS
    // headers: {
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*', //'https://localhost:5173/, https://www.brevit3.com, https://brevit3.com', 
    //     //'Access-Control-Allow-Credentials': 'true'
    // },
});

const PublicAI = () => {
    const [question, setQuestion] = useState('');
    const [chat, setChat] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [threadId, setThreadId] = useState(null);
    const [chatUsed, setChatUsed] = useState(false);
    const inputRef = useRef(null);
    const [questionHTML, setQuestionHTML] = useState('');
 
    const handleAsk = async () => {
        console.log('handleAsk');
        console.log('question:', question);
        console.log('questionHTML:', questionHTML);
        if (!question.trim()) return;

        console.log('Asking:', questionHTML);
        console.log('inputRef:', inputRef.current.textContent);

        const textInformation = inputRef.current.textContent;

        const userMessage = { role: 'user', content: textInformation };
        setChat(prevChat => [...prevChat, userMessage]);
        setChatUsed(true);
        setIsLoading(true);

        try {
            const response = await api.post(
                //'https://us-central1-brevit3-57b00.cloudfunctions.net/callOpenAI',
                'http://localhost:5001/brevit3-57b00/us-central1/callOpenAI',
                { question: textInformation, threadId }
            );

            setQuestion('');
            setQuestionHTML('');

            const data = response.data;
            console.log('Initial response:', data);

            if (!threadId) {
                setThreadId(data.threadId);
            }

            // Start polling for the answer
            await pollForAnswer(data.threadId, data.runId);

        } catch (error) {
            console.error('Fetch error:', error);
            const errorMessage = { role: 'bot', content: 'Sorry, there was an error.' };
            setChat(prevChat => [...prevChat, errorMessage]);
        } finally {
            //setIsLoading(false);
        }
    };

    const pollForAnswer = async (threadId, runId) => {
        const pollInterval = 1000; // Poll every 1 second
        const maxAttempts = 30; // Maximum number of attempts (30 seconds total)
        let attempts = 0;

        const poll = async () => {
            try {
                const response = await api.get(
                    //`https://us-central1-brevit3-57b00.cloudfunctions.net/checkRunStatus?threadId=${threadId}&runId=${runId}`
                    `http://localhost:5001/brevit3-57b00/us-central1/checkRunStatus?threadId=${threadId}&runId=${runId}`
                );

                const data = response.data;
                console.log('Poll response:', data);

                if (data.status === 'completed') {
                    // Assuming the last message in the thread is the assistant's response
                    //console.log('Poll response:', data);
                    //console.log('Poll response:', data.messages[0].content[0].text.value);
                    console.log("RESULTS...!!")
                    //console.log(JSON.parse(data.messages[0].content[0].text.value))



                    const pattern = /^""```json\s*|\s*""$/g;
          
                    // Clean the string
                    
                    const cleanedString = data.messages[0].content[0].text.value.replace(pattern, '');
                    //data.messages[0].content[0].text.value
                    const jsonObject = JSON.parse(cleanedString)

                    console.log(cleanedString)                    

                    setIsLoading(false);
                    const botMessage = { role: 'bot', content: cleanedString };
                    setChat(prevChat => [...prevChat, botMessage]);
                    return;
                }

                if (++attempts < maxAttempts) {
                    setTimeout(poll, pollInterval);
                } else {
                    throw new Error('Maximum polling attempts reached');
                }
            } catch (error) {
                console.error('Polling error:', error);
                const errorMessage = { role: 'bot', content: 'Sorry, there was an error getting the response.' };
                setChat(prevChat => [...prevChat, errorMessage]);
            }
        };

        await poll();
    };

    const removeCornerBracketContent = (text) => {
        return text.replace(/【[^】]*】/g, '').replace('```html', '').replace('```', '')
    }

    const presetPrompts = [
        'What is the Remarkable Demo Blueprint GPT?',
        'I am presenting <span class="genchat-highlight">software type</span> to <span class="genchat-highlight">company</span>. Can you help me brainstorm some ways to make my presentation extra engaging?',
        'How do I use the Remarkable Demo Blueprint GPT?',
        'If I am presenting in person, what are some techniques to keep people engaged?',
        'What are some ways to make my presentation more interactive?',
    ];

    const bookSections = [
        {
            icon: '/util/ai/b3-mindset-icon.svg', summary: 'Identify and break "content-first" habits, reframe our message with an audience-first mindset'
        },
        {
            icon: '/util/ai/b3-relevancy-icon.svg', summary: 'Deeply invest in understanding our audience, boosting our session\'s meaning and the value',
        },
        {
            icon: '/util/ai/b3-experience-icon.svg', summary: 'Explore techniques to enhance our ability to communicate in the most digestible, compelling way',
        },
        {
            icon: '/util/ai/b3-unstuck-icon.svg', summary: 'Feeling stuck?  Strategies and ideas to get a new perspective and help break through a sticking point',
        },
    ]

    const RemarkableDemoBlueprintDisplay = ({ content }) => {
        const cleanAndParseJSON = (jsonString) => {
          if (!jsonString) {
            return null;
          }
          // Regex pattern to remove extra characters
          const pattern = /^""```json\s*|\s*""$/g;
          
          // Clean the string
          const cleanedString = jsonString.replace(pattern, '');
          
          // Parse the cleaned string
          try {
            return JSON.parse(cleanedString);
          } catch (error) {
            console.error('Error parsing JSON:', error);
            return null;
          }
        };
      
        const data = cleanAndParseJSON(content);
      
        if (!content) {
          return <div>No data available</div>;
        }
      
        return (
          <div className="max-w-4xl mx-auto p-4 font-sans">
            <h1 className="text-3xl font-bold mb-6 text-gray-800">Remarkable Demo Blueprint</h1>
            
            <div className="bg-white shadow-md rounded-lg p-6 mb-8">
              <h2 className="text-xl font-semibold mb-4 text-gray-700">Summary</h2>
              <p className="text-gray-600">{data.summary}</p>
            </div>
            
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">Key Topics</h2>
            
            {data.details && data.details.map((item, index) => (
              <div key={index} className="bg-white shadow-md rounded-lg p-6 mb-6">
                <h3 className="text-lg font-semibold mb-4 text-gray-700">{item.topic}</h3>
                <p className="mb-4 text-gray-600">{item.content}</p>
                <div className="bg-gray-100 p-4 rounded-md">
                  <h4 className="font-semibold mb-2 text-gray-700">Example:</h4>
                  <p className="text-gray-600">{item.example}</p>
                </div>
              </div>
            ))}
          </div>
        );
      };
 
    return (
        <>
            <div className="relative isolate overflow-hidden b3-site-bg">
                <main>
                    <div className="relative isolate overflow-hidden">
                        <div className="py-24 sm:py-32">
                            <div className="mx-auto 2xl:px-8 xl:px-8 lg:px-8 md:px-0 sm:px-0 xs:px-0 xss:px-0">
                                <div className="flex flex-col items-center justify-center p-4">


                                    {/* <CompVideoCapture/> */}

                                    <h1 className="text-3xl font-light text-zinc-100 mb-4 text-center">Remarkable Demo Blueprint GPT</h1>
                                    <h1 className="text-2xl font-light text-zinc-300 mb-4 text-center">Try some of the sample prompts below or ask your question below</h1>
                                    <div className="xl:w-3/8 lg:w-7/8 md:w-7/8 mt-12 sm:w-full w-full shadow-lg  rounded-md 2xl:p-8 xl:p-8 lg:p-8 md:p-4 sm:p-2 xs:p-2 xss:p-2">
                                        {!chatUsed && (
                                            <div className="grid grid-cols-2 mb-4 gap-4 ">
                                                {presetPrompts.map((section, index) => (
                                                    <div key={index} className={`p-4  rounded-lg text-zinc-100 rounded 
                                                        border
                                                    border-transparent
                                                    hover:border-zinc-700
                                                    cursor-pointer  
                                                    transform-gpu
                                                    bg-gradient-to-r
                                                    from-zinc-900
                                                    via-zinc-800
                                                    to-zinc-900
                                                    background-animate-genai
                                                    genai-row-${index}
                                                    `}
                                                        onClick={() => {
                                                            setQuestion(section)
                                                        }}>
                                                        <div className="flex items-center text-zinc-300 text-lg
                                                        ">
                                                            <img src="/util/app/b3-genai-v2.svg" className="inline mr-4 w-5" />
                                                            <span
                                                                className="askremarkable-reply"
                                                                dangerouslySetInnerHTML={{ __html: removeCornerBracketContent(section) }}
                                                            >
                                                                
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        <div className="flex flex-col space-y-4 mb-4">
                                            {chat.map((message, index) => (
                                                <div
                                                    key={index}
                                                    className={`p-4 mt-14 mb-14 rounded-tl-xl rounded-tr-xl animate__animated animate__fadeInUp text-lg ${message.role === 'user'
                                                        ? 'bg-zinc-800 text-white self-end rounded-bl-xl'
                                                        : 'text-zinc-400 self-start w-3/4 pl-16'
                                                        }`}
                                                >
                                                    {message.role !== 'user' && (
                                                        <img
                                                            src="/android-chrome-192x192.png"
                                                            className="h-12 pt-2 absolute xxs:left-2 xs:left-2 sm:left-0 md:-left-1 lg:-left-1 xl:-left-1"
                                                        />
                                                    )}
                                                    
                                                    {message.role === 'user' ? 
                                                    <div
                                                        className="askremarkable-reply"
                                                        dangerouslySetInnerHTML={{ __html: removeCornerBracketContent(message.content) }}
                                                    >                                                          
                                                    </div>
                                                    : 
                                                    <div
                                                        className="askremarkable-reply"
                                                        dangerouslySetInnerHTML={{ __html: 'xxx xxx xxx' + removeCornerBracketContent(message.content) }}
                                                        // {JSON.parse(message.content)}
                                                    >
                                                        {/* <RemarkableDemoBlueprintDisplay data={message.content} /> */}
                                                    </div>
                                                    }
                                                </div>
                                            ))}
                                        </div>

                                        {isLoading && (
                                            <div className="flex space-x-2 w-full justify-center mx-auto mt-4">
                                                <div className="container-audio">
                                                    {[...Array(7)].map((_, i) => (
                                                        <div key={i} className="colum1">
                                                            <div className="row"></div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        <div className="flex space-x-2">
                                            <div className="relative w-full">

                                                <div
                                                    ref={inputRef}
                                                    contentEditable
                                                    placeholder="Type your question here"
                                                    className="flex-1 p-5 mt-14 transition ease-in-out w-full text-zinc-100 border rounded-md focus:outline-none border-2 border-zinc-800 focus:border-2 focus:border-sky-500 bg-black/20 pr-14"
                                                    onKeyDown={(e) => e.key === 'Enter' && !e.shiftKey && (e.preventDefault(), handleAsk())}
                                                    dangerouslySetInnerHTML={{ __html: question || 'Type your question here...' }}
                                                    id="question"
                                                />

                                                <div className="absolute top-2 right-2">
                                                    <button
                                                        onClick={handleAsk}
                                                        className="px-4 py-2.5 transition ease-in-out mt-14 text-lg bg-zinc-700 text-white rounded-md hover:bg-zinc-600"
                                                    >
                                                        Ask
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <CompRegister />

                                    <div className="m-8 mb-8 
                  xs:mt-0 sm:mt-0 md:mt-0  
                  xs:mb-4 sm:mb-4 md:mb-4 lg:mb-8
                  relative mt-6 p-0.5 inline-flex items-center justify-center cursor-pointer overflow-hidden group rounded-md"
                                        onClick={() => {
                                            //setOpen(true)
                                        }}>
                                        <span className="w-full h-full bg-gradient-to-br from-[#ff8a05] via-[#ff5478] to-[#ff00c6] group-hover:from-[#ff00c6] group-hover:via-[#ff5478] group-hover:to-[#ff8a05] absolute"></span>
                                        <span className="w-full text-center relative px-6 py-3 transition-all ease-out bg-zinc-900 rounded-md group-hover:bg-opacity-0 duration-400">
                                            <span className="relative text-white text-xl">Download Remarkable Demo Blueprint</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default PublicAI;