import React, { useRef, useState, useEffect, useMemo, Suspense, useContext } from "react";
import { useLoader, useFrame, useThree } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import { useAnimations, useGLTF, OrbitControls, useVideoTexture, TransformControls } from '@react-three/drei';
// import { v4 as uuidv4 } from 'uuid'
// import { EffectComposer, Outline, Selection, Select } from '@react-three/postprocessing';
import  { SessionContext  } from "../AppStoryPlayer";

const ExpModel = ({ vizItem, modelPath, scale = 40, rotation = [0, 0, 0], position = [0, 0, 0], positionEnd = [0, 0, 0], duration = 2 }) => {
  const [hovered, setHovered] = useState(false)
  const [positionStart, setPositionStart] = useState(position)
  const { size, viewport } = useThree();
  const aspect = size.width / viewport.width;
  const transform = useRef()
  const orbit = useRef()
  const modelRef = useRef()
  const boundingBoxRef = useRef()
  const wireframeRef = useRef()

  const [sessionData, setSessionData] = useContext(SessionContext)

  useEffect(() => {
    if (transform.current) {
      const controls = transform.current
      controls.setMode("translate")
      const callback = event => (orbit.current.enabled = !event.value)
      controls.addEventListener("dragging-changed", callback)
      return () => controls.removeEventListener("dragging-changed", callback)
    }
  })  

  const model = useLoader(GLTFLoader, modelPath)

  const { DEG2RAD } = THREE.MathUtils

  function Screen({ src, position, scale, mediaWidth = 16, mediaHeight = 9 }) {
    const [video, setVideo] = useState()
    const ratio = 16 / 9
    const r = useMemo(() => (video ? video.videoWidth / video.videoHeight : ratio), [video, ratio])

    return (
      <mesh position={position} scale={scale}>
        <planeGeometry args={[mediaWidth, mediaHeight]} />
        <Suspense fallback={<meshStandardMaterial side={THREE.DoubleSide} wireframe />}>
          <VideoMaterial src={src} />
        </Suspense>
      </mesh>
    )
  }

  function VideoMaterial({ src }) {
    const texture = useVideoTexture(src)
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    texture.repeat.x = 1
    texture.offset.x = 1
    return <meshStandardMaterial map={texture} toneMapped={false} opacity={1} />
  }

  let mixer
  if (model.animations.length) {
    mixer = new THREE.AnimationMixer(model.scene);
    model.animations.forEach(clip => {
      const action = mixer.clipAction(clip)
      action.play();
    });
  }

  useFrame((state, delta) => {
    mixer?.update(delta)
    
    // Update bounding box position and size
    if (modelRef.current && boundingBoxRef.current && wireframeRef.current) {
      const box = new THREE.Box3().setFromObject(modelRef.current)
      const size = new THREE.Vector3()
      box.getSize(size)
      const sizeMult = .7
      const boxSize = [size.x + sizeMult, size.y + sizeMult, size.z + sizeMult]
      boundingBoxRef.current.scale.set(...boxSize)
      wireframeRef.current.scale.set(...boxSize)
      const newPosition = [
        modelRef.current.position.x,
        modelRef.current.position.y + size.y / 2,
        modelRef.current.position.z //+ 1
      ]
      boundingBoxRef.current.position.set(...newPosition)
      wireframeRef.current.position.set(...newPosition)
    }
  })

  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto'
  }, [hovered])

  const activateSidebar = (id) => {
    const el1 = document.querySelector('div[data-itemguid="' + id + '"]');
    el1.classList.add('active');
    var accordionPanels = document.querySelectorAll('.accordion__panel');
    for (var i = 0; i < accordionPanels.length; i++) {
      var panel = accordionPanels[i];
      if (panel.getAttribute("data-itemguid") === id) {
        panel.removeAttribute("hidden");
      }
      else {
        panel.setAttribute("hidden", "");
      }
    }
    const buttons = document.querySelectorAll('.accordion__button');
    for (var i = 0; i < buttons.length; i++) {
      var button = buttons[i];
      if (button.getAttribute("data-itemguid") === id) {
        button.setAttribute('aria-expanded', 'true');
        button.classList.add('last-accordion-button');
      }
      else {
        button.setAttribute('aria-expanded', 'false');
        button.classList.remove('last-accordion-button');
      }
    }
  }

  const deg2radian = (degrees) => {
    var pi = Math.PI;
    return degrees * (pi/180);
  }

  if (sessionData.playModeActive != 1) {
  return (
    <>
      <group position={positionStart}>
        {vizItem.mediaEmbed &&
          <Screen
            src={vizItem.mediaEmbed.source}
            position={[vizItem.mediaEmbed.offset.x, vizItem.mediaEmbed.offset.y, vizItem.mediaEmbed.offset.z]}
            scale={vizItem.mediaEmbed.scale}
            mediaHeight={vizItem.mediaEmbed.mediaHeight || 9}
            mediaWidth={vizItem.mediaEmbed.mediaWidth || 16}
          />
        }

        {/*
        Claude, please do the following
        ADD 4 primitives here, one on the left, one on the right, one in the front and one on the top
        the left and right primitives can be dragged and dropped, moving the group along the X axis
        the top primitive can be dragged and dropped, moving the group along the Y axis
        the front primitive can be dragged and dropped, moving the group along the Z axis
        */}

        <primitive
          ref={modelRef}
          object={model.animations.length > 0 ? model.scene : model.scene.clone()}
          scale={scale}
          rotation={[deg2radian(rotation[0]), deg2radian(rotation[1]), deg2radian(rotation[2])]}
          onClick={(e) => activateSidebar(vizItem.guid)}
        />
        <mesh
          ref={boundingBoxRef}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          <boxGeometry args={[1, 1, 1]} />
          <meshPhongMaterial color="#38BDF8" transparent opacity={hovered ? 0.3 : 0} />
        </mesh>
        <lineSegments ref={wireframeRef}>
          <edgesGeometry args={[new THREE.BoxGeometry(1, 1, 1)]} />
          <lineDashedMaterial color="#38BDF8" dashSize={0.1} gapSize={0.05} opacity={hovered ? 1 : 0} transparent />
        </lineSegments>
      </group>
    </>
  )
  } else {
    return (
      <>
        <group position={positionStart}>
          {vizItem.mediaEmbed &&
            <Screen
              src={vizItem.mediaEmbed.source}
              position={[vizItem.mediaEmbed.offset.x, vizItem.mediaEmbed.offset.y, vizItem.mediaEmbed.offset.z]}
              scale={vizItem.mediaEmbed.scale}
              mediaHeight={vizItem.mediaEmbed.mediaHeight || 9}
              mediaWidth={vizItem.mediaEmbed.mediaWidth || 16}
            />
          }
          <primitive
            ref={modelRef}
            object={model.animations.length > 0 ? model.scene : model.scene.clone()}
            scale={scale}
            rotation={[deg2radian(rotation[0]), deg2radian(rotation[1]), deg2radian(rotation[2])]}
            onClick={(e) => activateSidebar(vizItem.guid)}
          />
        </group>
      </>
    )
  }
};

export default React.memo(ExpModel);