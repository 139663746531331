import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const logCustomEvent = (eventName, eventParams = {}) => {
  const analytics = getAnalytics();
  const auth = getAuth();
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  // Add userId to eventParams if available
  if (userId) {
    eventParams.userId = userId;
  }

  // Log the custom event
  logEvent(analytics, eventName, eventParams);

  // Log the event to the console
  // console.log(`Custom event logged: ${eventName}`, eventParams);

};

// Example usage
// const track3DModelGeneration = (modelId) => {
//   logCustomEvent("3D_Model_Generation", { modelId });
// };


// const trackEvent = (modelId) => {
//   logCustomEvent("3D_Model_Generation", { modelId });
// };


export { logCustomEvent };