import React, { useRef, useState, useContext, Fragment, useEffect, Suspense, CSSProperties } from "react";
import { analytics, db } from '../firebase'
import {
    query,
    collection,
    onSnapshot,
    updateDoc,
    orderBy,
    doc,
    where,
    addDoc,
    deleteDoc, serverTimestamp
} from 'firebase/firestore'
import { UserAuth } from '../context/AuthContext';
import { Dialog, Transition, Listbox, Menu } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link, Outlet, Navigate, useNavigate } from 'react-router-dom'
import { RadioGroup } from '@headlessui/react'
import BarLoader from "react-spinners/BarLoader";
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";
import { ToastContainer, toast } from 'react-toastify';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import hotkeys from 'hotkeys-js';

//import { useNotificationCenter } from "react-toastify/addons/useNotificationCenter"
import 'react-toastify/dist/ReactToastify.css';
import { ArcDiagram } from '../analytics/ArcDiagram'
import { LineChart, Line, Pie, PieChart, XAxis, YAxis, CartesianGrid, BarChart, Bar, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { CopyToClipboard } from 'react-copy-to-clipboard';

const Share = (props) => {
    const { user, logOut } = UserAuth();

    let [loading, setLoading] = useState(true)
    let [color, setColor] = useState("#0ca5e9")

    //const [showModal, setShowModal] = useState(false);
    const [open, setOpen] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    // Advanced
    const cancelButtonRef = useRef(null)
    const [stories, setStories] = useState([])
    const [shares, setShares] = useState([])

    const [shareName, setShareName] = useState('')
    const [shareDescription, setShareDescription] = useState('')
    const [shareStory, setShareStory] = useState('')
    const [shareURL, setShareURL] = useState('')
    const [shareNameError, setShareNameError] = useState(false);
    const [shareURLError, setShareURLError] = useState(false);
    const [shareStoryError, setShareStoryError] = useState(false);
    const [shareFriendlyURL, setShareFriendlyURL] = useState('')

    const [sharesLoaded, setSharesLoaded] = useState(false)
    const [storiesLoaded, setStoriesLoaded] = useState(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    

    // useEffect(() => {

    // }, [shareStory]


    const createShare = async () => {

        let isValid = true;

        if (shareName === '') {
            setShareNameError(true);
            isValid = false;
        } else {
            setShareNameError(false);
        }

        if (shareStory === '') {
            setShareStoryError(true);
            isValid = false;
        } else {
            setShareStoryError(false);
        }

        if (shareURL === '') {
            setShareURLError(true);
            isValid = false;
        } else {
            setShareURLError(false);
        }

        if (!isValid) {
            return;
        }


        // Grab the user ID...
        let userOwnerKey = user?.uid

        const docRef = await addDoc(collection(db, 'shares'), {
            name: shareName,
            description: shareDescription,
            userPhotoURL: user?.photoURL,
            userEmail: user?.email,
            userDisplayName: user?.displayName,
            messageUserId: user?.uid,
            inviteTitle: '',
            inviteMessage: '',
            inviteAddresses: [
                'mike@brevit3.com', 'olive@brevit3.com'
            ],
            storyName: shareStory.name,
            storyId: shareStory.id,
            shareFriendlyURL: shareURL,
            createdAt: serverTimestamp(),
            lastModified: serverTimestamp(),
            //analyticsViewTotal: 0,
            analyticsLastViewed: serverTimestamp(),
            imageStoryBackground: shareStory.imageStoryBackground, // '/media/icon/B3-Image-Placeholder.png', // TODO - add default image
            imageStoryLogo: shareStory.imageStoryLogo, //'/media/icon/B3-Image-Placeholder.png', // TODO - add default image
            role: {
                //[userOwnerKey]: 'owner',
                owner: [userOwnerKey], //t8X5CAmys8W88z5rOQWEQaIWhHn2
                commenter: [userOwnerKey],
                writer: [userOwnerKey],
                reader: [userOwnerKey],
            },
        });

        // Reset form fields
        setShareName('');
        setShareDescription('');
        setShareURL('');
        setOpen(false);
        //navigate('/app/stories/' + docRef.id + '/edit/0')
    };

    useEffect(() => {
        setTimeout(() => {
            if (user?.uid) {

                const q = query(collection(db, 'shares'), orderBy('lastModified', 'desc'), where("role.owner", "array-contains", user?.uid));

                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    let sharesArr = [];
                    querySnapshot.forEach((doc) => {
                        sharesArr.push({ ...doc.data(), id: doc.id })
                    });
                    setShares(sharesArr)
                    setSharesLoaded(true)
                    console.log('Shares loaded')
                    console.log(sharesArr)
                });
                return () => unsubscribe()
            }
        }, 250)
    }, [user])



    // >> Analytics
    const [analytics, setAnalytics] = useState([])
    const [analyticsCount, setAnalyticsCount] = useState([])
    const [analyticsLoaded, setAnalyticsLoaded] = useState(false)


    function countEngagementTags(engagementData) {
        const tagCountMap = new Map();
        const tagTimeMap = new Map();
        engagementData.forEach((engagement) => {
            engagement.engagementTags.forEach((tag) => {
                if (tagCountMap.has(tag)) {
                    tagCountMap.set(tag, tagCountMap.get(tag) + 1);
                    tagTimeMap.set(tag, tagTimeMap.get(tag) + engagement.engagementTime);
                } else {
                    tagCountMap.set(tag, 1);
                    tagTimeMap.set(tag, engagement.engagementTime);
                }
            });
        });
        const tagCountArray = Array.from(tagCountMap.entries()).map(([name, value]) => ({ name, value, totalEngagementTime: tagTimeMap.get(name) }));
        return tagCountArray;
    }

    function aggregateItems(items) {
        // Create a map to store the count of each id
        const itemCountMap = new Map();

        // Iterate through the items array
        items.forEach(item => {
            if (itemCountMap.has(item.sceneIndex)) {
                // If the id is already in the map, increment the count
                const existingItem = itemCountMap.get(item.sceneIndex);
                existingItem.count += 1;
            } else {
                // If the id is not in the map, add it with a count of 1
                itemCountMap.set(item.sceneIndex, { id: item.sceneIndex, name: item.name, count: 1 });
            }
        });

        // Convert the map to an array
        const aggregatedItems = Array.from(itemCountMap.values());

        return aggregatedItems;
    }

    function countUniqueCombinations(items) {
        const combinationsMap = {};

        items.forEach(item => {
            const key = `${item.from}-${item.to}`;
            if (combinationsMap[key]) {
                combinationsMap[key].count++;
            } else {
                combinationsMap[key] = { from: item.from, to: item.to, count: 1 };
            }
        });

        return Object.values(combinationsMap);
    }

    useEffect(() => {
        setTimeout(() => {
            if (user?.uid) {

                const q = query(collection(db, 'analytics')); //, orderBy('lastModified', 'desc'), where("role.owner", "array-contains", user?.uid));

                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    let analyticsArr = [];
                    querySnapshot.forEach((doc) => {
                        analyticsArr.push({ ...doc.data(), id: doc.id })
                    });
                    setAnalytics(analyticsArr)

                    const analyticsData = {
                        "nodes": aggregateItems(analyticsArr),
                        "links": countUniqueCombinations(analyticsArr),
                    }

                    const tagCounts = countEngagementTags(analyticsArr);

                    setAnalytics(analyticsData)
                    setAnalyticsCount(tagCounts)

                    console.log('///////////  ARRAY COUNT FOR ANALYTICS ///////////')

                    setTimeout(() => {
                        setAnalyticsLoaded(true)
                    }, 500)

                });
                return () => unsubscribe()
            }
        }, 250)
    }, [user])
    // // Analytics


    useEffect(() => {
        console.log('Analytics Count')
        console.log(analyticsCount)
    }, [analyticsCount])


    useEffect(() => {
        setTimeout(() => {
            if (user?.uid) {
                const q = query(collection(db, 'stories'), orderBy('lastModified', 'desc'), where("role.owner", "array-contains", user?.uid));
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    let storiesArr = [];
                    querySnapshot.forEach((doc) => {
                        storiesArr.push({ ...doc.data(), id: doc.id })
                    });
                    setStories(storiesArr);
                    setStoriesLoaded(true)
                    console.log('Stories loaded')
                    console.log(storiesArr)
                });
                return () => unsubscribe()
            }
        }, 250)
    }, [user])



    const override = {
        width: "50%",
        margin: "auto",
        marginTop: "20%",
    }

    return (
        <div className="h-screen flex flex-col">

            <div className="md:flex md:items-center md:justify-between ">
                <div className="min-w-0 flex-1">
                    <h2 className="text-2xl font-normal leading-7 text-gray-200 sm:truncate sm:text-2xl mb-4 sm:tracking-tight">
                        Share
                    </h2>
                </div>
            </div>



            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenDialog}>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-700 bg-opacity-50 rounded-md transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto"
                    >
                        <div className="flex min-h-full items-end justify-center text-center rounded-md sm:items-center px-10">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >

                                <Dialog.Panel className={`relative transform overflow-hidden rounded-md bg-zinc-900 px-4 pb-2 pt-2 text-left
               shadow-xl transition-all sm:my-2 sm:w-full sm:w-none m-40 sm:p-10`}>
                                    <div>
                                        <div className="text-left">
                                            <Dialog.Title as="h3" className="text-xl leading-6 text-gray-100">
                                                Create New Share
                                            </Dialog.Title>


                                            <div className=" mt-8">

                                                <Menu as="div" className={`relative inline-block w-full text-left  rounded-md  ring-1 ring-outset  ${shareStoryError ? 'ring-sky-500' : 'ring-zinc-700'}`}>
                                                    {({ open }) => (
                                                        <>
                                                            <div className={`admin-dropdown rounded-md  `}>
                                                                <Menu.Button
                                                                    className="inline-flex w-full gap-x-1.5 rounded admin-bg-side admin-component pl-4 py-4 text-base font-normal text-gray-200"
                                                                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                                                >
                                                                    {shareStory ? shareStory.name : 'Select Story to Share'}
                                                                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 absolute right-6" aria-hidden="true" />
                                                                </Menu.Button>
                                                            </div>
                                                            <Transition
                                                                show={isDropdownOpen}
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="absolute left-0 z-10 w-full text-gray-200 origin-top rounded-md bg-black shadow-lg focus:outline-none">
                                                                    <div className="section-scroll h-64 grid grid-cols-1 gap-1 px-1">
                                                                        {stories.map((story, index) => (
                                                                            <Menu.Item key={story.id}>
                                                                                {({ active }) => (
                                                                                    <div
                                                                                        className={`w-full p-2 rounded ${active ? 'bg-white/20' : ''
                                                                                            } ${story.id === 'changeme' ? 'bg-white/20' : 'hover:bg-white/20 cursor-pointer'}`}
                                                                                        onClick={() => {
                                                                                            setShareStory(story)
                                                                                            setIsDropdownOpen(false)
                                                                                        }}
                                                                                    >
                                                                                        {story.name}
                                                                                    </div>
                                                                                )}
                                                                            </Menu.Item>
                                                                        ))}
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </>
                                                    )}
                                                </Menu>

                                                <div className="mt-8  pb-4">
                                                    <div className="text-zinc-100">
                                                        Internal Details
                                                    </div>
                                                    <div className={`flex rounded-md shadow-sm ring-1 ring-inset ${shareNameError ? 'ring-sky-500' : 'ring-gray-600'} focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-400 mt-4 focus:outline-none`}>
                                                        <input
                                                            type="text"
                                                            className={`block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset ${shareNameError ? 'ring-sky-500' : 'ring-zinc-700'} placeholder:text-zinc-500 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none`}
                                                            placeholder={shareNameError ? "Required - Share Name" : "Share Name"}
                                                            value={shareName}
                                                            onChange={(e) => {
                                                                setShareName(e.target.value);
                                                                setShareNameError(false);
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="mt-2">
                                                        <textarea
                                                            placeholder="Description"
                                                            rows={3}
                                                            className="block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                  ring-zinc-700 placeholder:text-zinc-500  focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                                                            value={shareDescription}
                                                            onChange={(e) => setShareDescription(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="mt-8 pb-4">
                                                    <div className="text-zinc-100">
                                                        External Details
                                                    </div>
                                                    {shareStory ?
                                                        <div className="flex">
                                                            <div className="flex rounded-md border-0 py-4 bg-transparent text-zinc-500 shadow-sm ring-1 ring-inset ring-transparent placeholder:text-gray-400 sm:text-base sm:leading-6 focus:outline-none mr-2">
                                                                https://www.brevit3.com/share/{shareStory.id}/v/
                                                            </div>

                                                            <input
                                                                type="text"
                                                                className={`flex-auto rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset ${shareURLError ? 'ring-sky-500' : 'ring-zinc-700'} placeholder:text-zinc-500 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none`}
                                                                placeholder={shareURLError ? "Required - shared_demo_name_here" : "shared_demo_name_here"}
                                                                value={shareURL}
                                                                onChange={(e) => {
                                                                    setShareURL(e.target.value);
                                                                    setShareURLError(false);
                                                                }}
                                                            />
                                                        </div>
                                                        :
                                                        <div className="flex">
                                                            <div className="flex rounded-md border-0 py-4 bg-transparent text-zinc-500 shadow-sm ring-1 ring-inset ring-transparent placeholder:text-gray-400 sm:text-base sm:leading-6 focus:outline-none mr-2">
                                                                please select a story before sharing
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="col-span-full">

                                                </div>
                                                <div className="mt-8  pb-4">
                                                    <div className="text-zinc-100 ">
                                                        Notify on open (optional)
                                                    </div>
                                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-600 focus-within:ring-2
                focus-within:ring-inset focus-within:ring-sky-400 mt-4 focus:outline-none">
                                                        <input
                                                            type="text"
                                                            className="block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                              ring-zinc-700 placeholder:text-zinc-500  focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                                                            placeholder="email@yourcompany.com"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-20 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 pt-4">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded bg-sky-500 px-3 py-2 text-base font-base text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 sm:col-start-2"
                                            onClick={createShare}
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded bg-gray-500 px-3 py-2 bg-zinc-800 text-base font-base text-gray-100 shadow-sm hover:bg-zinc-700 sm:col-start-1 sm:mt-0"
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <button onClick={() => setOpen(true)}
                type="button"
                className=" border border-sky-500   hover:bg-gray-900 flex  aspect-video rounded items-center justify-center font-medium text-sky-500   aspect-video rounded w-40
                 hover:border-sky-500 hover:border
                aspect-h-4 aspect-w-2 rounded bg-story-preview duration-300 border border-gray-800 shadow  py-4 h-32
                ">
                <div className="flex items-stretch">

                    <div className="">
                        <img src="/media/icon/new.svg" className="h-18 w-8 margin-auto" />
                        <div className="mt-4 md:text-sm  text-sm">New Share</div>
                    </div>

                </div>
            </button>

            <div className="overflow-y-auto admin-scroll mb-12 mt-12">
                <div className="mx-auto ">

                    <div className="w-full">
                        <BarLoader
                            color={color}
                            loading={!storiesLoaded}
                            cssOverride={override}
                            size={450}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>

                    <div className={"grid grid-cols-1 gap-4 text-zinc-100 pr-4"}>

                        {sharesLoaded && shares.length === 0 ?

                            <div className="bg-black/20 rounded-md p-6 flex col-span-x">
                                
                                <img src={'/media/icon/b3-admin-share.svg'} className="w-12 rounded-md inline mr-4" />
                                
                                <div className="">
                                <div className="text-lg text-gray-50  ">No shares found</div>
                                <div className="text-sm mt-1 text-gray-400">Create a new share to get started</div>
                                </div>
                            </div>
                        :
                            null
                        }

                        {shares.map((share, index) => (
                            <div key={index} className="bg-black/20 rounded-md gap-4 p-6 grid grid-cols-2">
                                <div className="flex justify-between">
                                    <div className="flex gap-4">
                                        {/* <img src={share.imageStoryLogo} className="w-24 rounded-md " /> */}

                                        <div className="bg-contain bg-center w-24 bg-no-repeat mr-2"
                                            style={{ backgroundImage: `url(${share.imageStoryLogo})` }}
                                        ></div>

                                        <div>
                                            <div className="text-lg text-gray-50  ">{share.name}</div>
                                            <div className="text-sm mt-1 text-gray-400">{share.description}</div>
                                            <div className="text-sm mt-1 text-gray-400">
                                                {share.createdAt?.toDate()?.toDateString()}
                                            </div>
                                            {/* <div className="text-sm mt-1 text-gray-400">Last Viewed</div> */}
                                        </div>
                                    </div>

                                </div>
 

                                <div className="flex gap-4  text-base">

                                    <a href={'/app/stories/' + share.storyId + '/edit/0'}
                                        target="_blank"
                                        className="  justify-center rounded border-white/5 hover:bg-white/5 px-3 py-2 pt-3"
                                        onClick={() => {
                                            //navigate('/app/stories/' + story.id + '/edit/0')
                                        }}
                                    >
                                        Edit Story <img src="/media/icon/noun-link-5006217-FFFFFF.svg" className="h-8 ml-4 o5 inline"></img>
                                        {/* {share.storyId} */}
                                    </a>




                                    {/* <div className="flex-none w-14 h-14">
                                      <div className="mt-2 ml-4">
                                        <a href={copied.value} target="_blank">
                                          <img src="/media/icon/noun-link-5006217-FFFFFF.svg" className="h-10 w-10 o5" /></a>
                                      </div>
                                    </div> */}
                                    <div className="  justify-center rounded border-white/5 hover:bg-white/5 px-3 py-2 pt-3 -mt-1">
                                      <CopyToClipboard text={'https://www.brevit3.com/share/'  + share.storyId + '/v/' + share.shareFriendlyURL}
                                        onCopy={() => 
                                        //setCopied({ value: 'https://www.brevit3.com/share/' +  share.storyId + '/v/' + share.shareFriendlyURL, copied: true })
                                        //console.log('copied')
                                        //toast("Copied to clipboard - " + 'https://www.brevit3.com/share/'  + share.storyId + '/v/' + share.shareFriendlyURL)

                                        toast.info("Copied to clipboard - " + 'https://www.brevit3.com/share/'  + share.storyId + '/v/' + share.shareFriendlyURL, {
                                            position: "bottom-center",
                                            autoClose: 5000,
                                            hideProgressBar: true,
                                            // closeOnClick: true,
                                            // pauseOnHover: true,
                                            // draggable: false,
                                            // progress: undefined,
                                            theme: "dark",
                                            data: {
                                              //item: item,
                                              time: Date.now() //serverTimestamp()
                                            },
                                            //transition: "Slide",
                                          })

                                        } 
                                        >
                                        <button>
                                            Copy sharing link
                                            <img src="/media/icon/noun-copy-2757462-FFFFFF.svg" className="h-10 ml-2 o5 inline " /></button>
                                      </CopyToClipboard>
                                    </div>
                                    <div className="flex-none w-14 h-14">
                                      {/* {copied.copied ? <div className="text-sky-400 animate__animated animate__fadeIn mt-3">copied</div> : null} */}
                                    </div>
                                  </div>


                                  {/* <a href={'/share/' + share.storyId + '/v/' + share.shareFriendlyURL}
                                        target="_blank"
                                        className="  justify-center rounded border-white/5 hover:bg-white/5 px-3 py-2"
                                    >
                                        Open Share <img src="/media/icon/noun-link-5006217-FFFFFF.svg" className="h-8 o5 inline"></img>
                                      
                                    </a> */}

                            </div>
                        ))}
                    </div>

                    <ToastContainer
        // position="bottom-center"
        // autoClose={5000}
        // limit={5}
        // hideProgressBar={false}
        // newestOnTop
        // closeOnClick
        // rtl={false}
        // //pauseOnFocusLoss
        // draggable={false}
        // pauseOnHover
        // theme="dark"
        // transition="Slide"
        transition={Slide}
      />

                </div>
            </div>
        </div>
    )
}

export default Share