import React, { useRef, useState, useContext, forwardRef, useEffect, useCallback, useMemo } from "react";
import { useFrame, useThree, useLoader  } from "@react-three/fiber";
import { Html, useProgress, Loader, Billboard, PivotControls, Box, Sphere } from '@react-three/drei'
import QRCode from "react-qr-code";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ReactPlayer from 'react-player'
import UserContext, { SessionContext, SceneContext, StoryContext } from "../AppStoryPlayer";

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
//import { SessionContext, SceneContext, StoryContext } from '../AppStoryExperience'
//import ImageGallery from 'react-image-gallery';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index"

import { v4 as uuidv4 } from 'uuid'
//import anime from "animejs";
import * as THREE from "three";
import { clone } from 'three/examples/jsm/utils/SkeletonUtils'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

//import { useDrag } from "react-use-gesture"

ChartJS.register(ArcElement, Tooltip, Legend);

//import { emitCount, emitVideoPlayer } from '../events/bridge'
import { useCountListener, emitCount } from '../events/bridge'

// { useVideoPlayer, emitVideoPlayer }
import { useVidListener, emitVid } from '../events/bridge'
import { useOnChangeListener, emitOnChange, emitMovePos } from '../events/bridge'


import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"

import { TableauEmbed } from "@stoddabr/react-tableau-embed-live";

//import GoogleDocsViewer from 'react-google-docs-viewer';
//import { platform } from "os";

export const data = {
  labels: ['Red', 'Blue', 'Yellow'],
  datasets: [
    {
      label: 'Data',
      data: [12, 19, 3],
      backgroundColor: [
        'rgba(255, 99, 132, .8)',
        'rgba(54, 162, 235, .8)',
        'rgba(255, 206, 86, .8)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
      ],
      borderWidth: 5,
      // spacing: 10,
    },
  ],
};


// const el1 = document.querySelector('div[data-id="box1"]');
// // console.log(el1); // 👉️ div

const activateSidebar = (id) => {
  const el1 = document.querySelector('div[data-itemguid="' + id + '"]');
  el1.classList.add('active');
  var accordionPanels = document.querySelectorAll('.accordion__panel');
  for (var i = 0; i < accordionPanels.length; i++) {
    var panel = accordionPanels[i];
    if (panel.getAttribute("data-itemguid") === id) {
      panel.removeAttribute("hidden");
    }
    else {
      panel.setAttribute("hidden", "");
    }
  }
  const buttons = document.querySelectorAll('.accordion__button');
  for (var i = 0; i < buttons.length; i++) {
    var button = buttons[i];
    if (button.getAttribute("data-itemguid") === id) {
      button.setAttribute('aria-expanded', 'true');
      button.classList.add('last-accordion-button');

      // set timeoout and then remove last-accord...
      setTimeout(function () {
        button.classList.remove('last-accordion-button');
        button.classList.remove('last-accordion-panel');
        //last-accordion-panel
      }, 3000);
    }
    else {
      button.setAttribute('aria-expanded', 'false');
      button.classList.remove('last-accordion-button');
    }
  }
}

function replaceAll(string, search, replace) {
  return string.split(search).join(replace);
}


const useGLTF = (path) => {
  const gltf = useLoader(GLTFLoader, path)
  return useMemo(() => {
    const clonedScene = clone(gltf.scene)
    return { ...gltf, scene: clonedScene }
  }, [gltf])
}

// const DraggableMesh = ({ geometry, position, color, onDragStart, onDragEnd, htmlContent, scale, zIndexRange, vizItem, vizType, vizLevel, sessionData, modelPath, noOcclusion = true, setOrbitActive }) => {
//   const meshRef = useRef()
//   const materialRef = useRef()
//   const boundingBoxRef = useRef()
//   const wireframeRef = useRef()
//   const [isDragging, setIsDragging] = useState(false)
//   const [isHovered, setIsHovered] = useState(false)
//   const { camera, gl, size } = useThree()
//   const dragStart = useRef(new THREE.Vector2())
//   const initialPosition = useRef(new THREE.Vector3())
//   const yOffset = -10

//   const gltf = useGLTF(modelPath)

//   const getMousePosition = useCallback((event) => {
//     const vec = new THREE.Vector3()
//     const pos = new THREE.Vector3()

//     vec.set(
//       (event.clientX / size.width) * 2 - 1,
//       -(event.clientY / size.height) * 2 + 1,
//       0.5
//     )

//     vec.unproject(camera)
//     vec.sub(camera.position).normalize()
//     const distance = -camera.position.z / vec.z
//     pos.copy(camera.position).add(vec.multiplyScalar(distance))

//     return pos
//   }, [camera, size])

//   const onPointerDown = useCallback((event) => {
//     event.stopPropagation()
//     setIsDragging(true)
//     setOrbitActive(0)
//     onDragStart()
//     gl.domElement.style.cursor = 'grabbing'
//     const mousePos = getMousePosition(event)
//     dragStart.current.set(mousePos.x, mousePos.y)
//     initialPosition.current.copy(meshRef.current.position)
//   }, [gl, getMousePosition, onDragStart, setOrbitActive])

//   const onPointerMove = useCallback((event) => {
//     if (!isDragging) return
//     const currentPosition = getMousePosition(event)
//     const dragDeltaX = currentPosition.x - dragStart.current.x
//     const dragDeltaY = currentPosition.y - dragStart.current.y
//     meshRef.current.position.x = initialPosition.current.x + dragDeltaX
//     meshRef.current.position.y = initialPosition.current.y + dragDeltaY
//   }, [isDragging, getMousePosition])

//   const onPointerUp = useCallback(() => {
//     setIsDragging(false)
//     onDragEnd()
//     setOrbitActive(1)

//     emitCount(
//       { uuid: vizItem.guid, vizItem: vizItem, x: -1 * meshRef.current.position.x, y: meshRef.current.position.y - yOffset, z: 0 }
//     )

//     gl.domElement.style.cursor = 'auto'
//   }, [gl, onDragEnd, setOrbitActive, vizItem, yOffset])

//   useEffect(() => {
//     const domElement = gl.domElement
//     domElement.addEventListener('pointermove', onPointerMove)
//     domElement.addEventListener('pointerup', onPointerUp)
//     return () => {
//       domElement.removeEventListener('pointermove', onPointerMove)
//       domElement.removeEventListener('pointerup', onPointerUp)
//     }
//   }, [gl, onPointerMove, onPointerUp])

//   const onPointerOver = () => {
//     gl.domElement.style.cursor = 'grab'
//     setIsHovered(true)
//   }

//   const onPointerOut = () => {
//     gl.domElement.style.cursor = 'default'
//     setIsHovered(false)
//   }

//   useEffect(() => {
//     if (gltf.scene) {
//       gltf.scene.traverse((child) => {
//         if (child.isMesh) {
//           child.material = new THREE.MeshPhongMaterial({
//             color: '#0284C7',
//             depthTest: !noOcclusion,
//             transparent: noOcclusion,
//             opacity: noOcclusion ? 0.99 : 1,
//           })
          
//           if (noOcclusion) {
//             child.renderOrder = 999
//           }
//         }
//       })
//     }
//   }, [gltf, noOcclusion])

//   useEffect(() => {
//     if (gltf.scene) {
//       gltf.scene.traverse((child) => {
//         if (child.isMesh) {
//           if (isDragging) {
//             child.material.color.set('#7DD3FC')
//           } else if (isHovered) {
//             child.material.color.set('#38BDF8')
//           } else {
//             child.material.color.set('#0284C7')
//           }
//         }
//       })
//     }
//   }, [isDragging, isHovered, gltf])

//   useFrame(() => {
//     if (meshRef.current && boundingBoxRef.current && wireframeRef.current) {
//       const box = new THREE.Box3().setFromObject(meshRef.current)
//       const size = new THREE.Vector3()
//       box.getSize(size)
//       const boxSize = [size.x + 0.1, size.y + 0.1, size.z + 0.1]
//       boundingBoxRef.current.scale.set(...boxSize)
//       wireframeRef.current.scale.set(...boxSize)
//       const newPosition = [
//         meshRef.current.position.x,
//         meshRef.current.position.y, //+ size.y / 2,
//         meshRef.current.position.z
//       ]
//       boundingBoxRef.current.position.set(...newPosition)
//       wireframeRef.current.position.set(...newPosition)
//     }
//   })

//   return (
//     <group position={position}>
//       <primitive
//         object={gltf.scene}
//         ref={meshRef}
//         position={[0, yOffset, 0]}
//         scale={scale}
//         onPointerDown={onPointerDown}
//         onPointerOver={onPointerOver}
//         onPointerOut={onPointerOut}
//       >
//         <Html
//           rotation={vizType == 14 ? [THREE.MathUtils.degToRad(90), 0, 0] : [0, 0, 0]}
//           position={[0, 10, 0]}
//           transform={true}
//           scale={vizType == 9 || vizType == 10 ? 1.1 : vizItem.scale || 1}
//           fullscreen
//           zIndexRange={vizLevel}
//           onPointerOver={(event) => setIsHovered(true)}
//           onPointerOut={(event) => setIsHovered(false)}
//         >
//           <div
//             className={`vizoverlay ${sessionData.playModeActive != 1 ? 'hover:bg-white/20 p-3 transition ease-in-out hover:scale-105  border-dashed hover:border-4 border-sky-300 rounded-lg cursor-pointer' : ''}`}
//             style={{ userSelect: 'none' }}
//           >
//             {htmlContent}
//           </div>
//         </Html>
//       </primitive>
      
//       <mesh
//         ref={boundingBoxRef}
//         visible={isHovered || isDragging}
//       >
//         <boxGeometry args={[1, 1, 1]} />
//         <meshPhongMaterial color="#38BDF8" transparent opacity={0.3} />
//       </mesh>
      
//       <lineSegments
//         ref={wireframeRef}
//         visible={isHovered || isDragging}
//       >
//         <edgesGeometry args={[new THREE.BoxGeometry(1, 1, 1)]} />
//         <lineDashedMaterial color="#38BDF8" dashSize={0.1} gapSize={0.05} />
//       </lineSegments>
//     </group>
//   )
// }


const DraggableMesh = ({ geometry, position, color, onDragStart, onDragEnd, htmlContent, scale, zIndexRange, vizItem, vizType, vizLevel, sessionData, modelPath, noOcclusion = true, setOrbitActive }) => {
  const meshRef = useRef()
  const boundingBoxRef = useRef()
  const wireframeRef = useRef()
  const [isDragging, setIsDragging] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const { camera, gl, size } = useThree()
  const dragStart = useRef(new THREE.Vector2())
  const initialPosition = useRef(new THREE.Vector3())
  const yOffset = -10

  const gltf = useGLTF(modelPath)

  const getMousePosition = useCallback((event) => {
    const vec = new THREE.Vector3()
    const pos = new THREE.Vector3()

    vec.set(
      (event.clientX / size.width) * 2 - 1,
      -(event.clientY / size.height) * 2 + 1,
      0.5
    )

    vec.unproject(camera)
    vec.sub(camera.position).normalize()
    const distance = -camera.position.z / vec.z
    pos.copy(camera.position).add(vec.multiplyScalar(distance))

    return pos
  }, [camera, size])

  const onPointerDown = useCallback((event) => {
    event.stopPropagation()
    setIsDragging(true)
    setOrbitActive(0)
    onDragStart()
    gl.domElement.style.cursor = 'grabbing'
    const mousePos = getMousePosition(event)
    dragStart.current.set(mousePos.x, mousePos.y)
    initialPosition.current.copy(meshRef.current.position)
  }, [gl, getMousePosition, onDragStart, setOrbitActive])

  const onPointerMove = useCallback((event) => {
    if (!isDragging) return
    const currentPosition = getMousePosition(event)
    const dragDeltaX = currentPosition.x - dragStart.current.x
    const dragDeltaY = currentPosition.y - dragStart.current.y
    meshRef.current.position.x = initialPosition.current.x + dragDeltaX
    meshRef.current.position.y = initialPosition.current.y + dragDeltaY
  }, [isDragging, getMousePosition])

  const onPointerUp = useCallback(() => {
    setIsDragging(false)
    onDragEnd()
    setOrbitActive(1)

    if (meshRef.current != null) {
    emitCount(
      { uuid: vizItem.guid, vizItem: vizItem, x: -1 * meshRef.current.position.x, y: meshRef.current.position.y - yOffset, z: 0 }
    )
  }

    gl.domElement.style.cursor = 'auto'
  }, [gl, onDragEnd, setOrbitActive, vizItem, yOffset])

  useEffect(() => {
    const domElement = gl.domElement
    domElement.addEventListener('pointermove', onPointerMove)
    domElement.addEventListener('pointerup', onPointerUp)
    return () => {
      domElement.removeEventListener('pointermove', onPointerMove)
      domElement.removeEventListener('pointerup', onPointerUp)
    }
  }, [gl, onPointerMove, onPointerUp])

  const onPointerOver = () => {
    gl.domElement.style.cursor = 'grab'
    setIsHovered(true)
  }

  const onPointerOut = () => {
    gl.domElement.style.cursor = 'default'
    setIsHovered(false)
  }

  useEffect(() => {
    if (gltf.scene) {
      gltf.scene.traverse((child) => {
        if (child.isMesh) {
          child.material = new THREE.MeshPhongMaterial({
            color: '#0284C7',
            depthTest: !noOcclusion,
            transparent: noOcclusion,
            opacity: noOcclusion ? 0.99 : 1,
          })
          
          if (noOcclusion) {
            child.renderOrder = 999
          }
        }
      })
    }
  }, [gltf, noOcclusion])

  useEffect(() => {
    if (gltf.scene) {
      gltf.scene.traverse((child) => {
        if (child.isMesh) {
          if (isDragging) {
            child.material.color.set('#0284C7')
          } else if (isHovered) {
            child.material.color.set('#38BDF8')
          } else {
            //child.material.color.set('#0284C7')
            child.material.color.set('#38BDF8')
            child.material.opacity = 0.75
          }
        }
      })
    }
  }, [isDragging, isHovered, gltf])

  useFrame(() => {
    if (meshRef.current && boundingBoxRef.current && wireframeRef.current) {
      const box = new THREE.Box3().setFromObject(meshRef.current)
      const size = new THREE.Vector3()
      box.getSize(size)
      const boxSize = [size.x + 0.3, size.y + 0.3, size.z + 0.3]
      boundingBoxRef.current.scale.set(...boxSize)
      wireframeRef.current.scale.set(...boxSize)
      const newPosition = [
        meshRef.current.position.x,
        meshRef.current.position.y,
        meshRef.current.position.z
      ]
      boundingBoxRef.current.position.set(...newPosition)
      wireframeRef.current.position.set(...newPosition)
    }
  })

  if (sessionData.playModeActive != 1) {
  return (
    <group position={position}>
      <primitive
        object={gltf.scene}
        ref={meshRef}
        position={[0, yOffset, 0]}
        scale={scale}
      >
        <Html
          rotation={vizType == 14 ? [THREE.MathUtils.degToRad(90), 0, 0] : [0, 0, 0]}
          position={[0, 10, 0]}
          transform={true}
          scale={vizType == 9 || vizType == 10 ? 1.1 : vizItem.scale || 1}
          fullscreen
          zIndexRange={vizLevel}
        >
          <div
            className={`vizoverlay ${sessionData.playModeActive != 1 ? 'hover:bg-white/20 p-3 transition ease-in-out hover:scale-105  border-dashed hover:border-4 border-sky-300 rounded-lg cursor-pointer' : ''}`}
            style={{ userSelect: 'none' }}
          >
            {htmlContent}
          </div>
        </Html>
      </primitive>
      
      <mesh
        ref={boundingBoxRef}
        onPointerDown={onPointerDown}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
      >
        <boxGeometry args={[1, 1, 1]} />
        <meshPhongMaterial color="#38BDF8" transparent opacity={isHovered || isDragging ? 0.2 : 0} />
      </mesh>
      
      <lineSegments
        ref={wireframeRef}
        visible={isHovered || isDragging}
      >
        {/* <edgesGeometry args={[new THREE.BoxGeometry(1, 1, 1)]} /> */}
        <lineDashedMaterial color="#38BDF8" dashSize={2} gapSize={0.5} />
      </lineSegments>
    </group>
  )
}
else {
return (
  <group position={position}>
      {/* <primitive
        object={gltf.scene}
        ref={meshRef}
        position={[0, yOffset, 0]}
        scale={scale}
      > */}
        <Html
          rotation={vizType == 14 ? [THREE.MathUtils.degToRad(90), 0, 0] : [0, 0, 0]}
          position={[0, 0, 0]}
          transform={true}
          scale={vizType == 9 || vizType == 10 ? 1.1 : vizItem.scale || 1}
          fullscreen
          zIndexRange={vizLevel}
        >
          <div
            className={`vizoverlay ${sessionData.playModeActive != 1 ? 'hover:bg-white/20 p-3 transition ease-in-out hover:scale-105  border-dashed hover:border-4 border-sky-300 rounded-lg cursor-pointer' : ''}`}
            style={{ userSelect: 'none' }}
          >
            {htmlContent}
          </div>
        </Html>
      {/* </primitive> */}
      
      {/* <mesh
        ref={boundingBoxRef}
        onPointerDown={onPointerDown}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
      >
        <boxGeometry args={[1, 1, 1]} />
        <meshPhongMaterial color="#38BDF8" transparent opacity={isHovered || isDragging ? 0.2 : 0} />
      </mesh> */}
      
      {/* <lineSegments
        ref={wireframeRef}
        visible={isHovered || isDragging}
      >
        <lineDashedMaterial color="#38BDF8" dashSize={2} gapSize={0.5} />
      </lineSegments> */}
    </group>
  )
}
}



const ExpHTML = ({ personName, personRole, displayImage, position, rotation = [0, 0, 0], vizType = 1, scale = 1, thisIndex = 0, props, vizItem, htmlPreview = 0, htmlPreviewScale = 1, setOrbitActive = null }) => {
  const [sessionData, setSessionData] = useContext(SessionContext)
  const [sceneData, setSceneData] = useContext(SceneContext)
  const [storyData, setStoryData] = useContext(StoryContext)

  const [isDragging, setIsDragging] = useState(false)
  const controlsRef = useRef()

  const handleDragStart = useCallback(() => {
    setIsDragging(true)
  }, [])

  const handleDragEnd = useCallback(() => {
    setIsDragging(false)
  }, [])

  const ref = useRef();
  const [hovered, hover] = useState(false);

  const DynamicContent = (props) => {


    if (vizType == 0 || vizType == 16) {




      if (vizItem.cardTitle == null) {
        return (
          <div className={`viz-needs-info  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__zoomIn" : "noshow"}`} onClick={() => activateSidebar(vizItem.guid)}>
            <h1>{vizType == 0 ? 'Super Card' : 'Icon'}</h1>
            <div>Select {vizType == 0 ? 'Super Card' : 'Icon'} component from right to configure</div>
            <div><img src="/media/icon/noun-1417899-FFFFFF.svg" className="w-1/2 mx-auto" /></div>
          </div>
        )
      }

      if (vizItem.cardLayout.id == 1) {
        return (
          <div
            // style={{ animationDelay: vizItem.animationDuration + 's' }}
            // className={`persona-card-body org-${storyData.name.replace(/\s+/g, '-').toLowerCase() || 'none'}`}
            className={`viz-needs-info  org-${storyData.name.replace(/\s+/g, '-').toLowerCase() || 'none'}  
              ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              background: storyData.uiStyle['super-main-bg-color'],
              color: storyData.uiStyle['super-main-title-color'],
              borderColor: storyData.uiStyle['super-highlight-color'],
              width: htmlPreview == 1 ? '100% ' : '400px',
              //height: htmlPreview == 1 ? '500px ' : 'initial',
              //marginTop: htmlPreview == 1 ? '-15%' : '',
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>
            <div
              className="text-left px-4 font-semibold text-3xl mb-2"
              style={{
                color: storyData.uiStyle['super-main-title-color']
              }}
            >
              <img src={vizItem.cardImage} className="w-1/3 mx-auto mb-8 mt-2" />
              {vizItem.cardTitle}
            </div>
            <div
              className="text-left px-4 pb-4"
              style={{
                color: storyData.uiStyle['super-subtitle-color']
              }}
            >
              {vizItem.cardSubtitle}
            </div>
            <div
              className="relative inline-flex text-left px-4 pb-8 mt-2"
              style={{
                color: storyData.uiStyle['super-body-color']
              }}
            >
              {/* {vizItem.cardBody} */}
              {vizItem.cardBody.replaceAll('*', '•')}
            </div>
          </div>
        )
      }
      else if (vizItem.cardLayout.id == 2) {
        // console.log('>>>>>> Supercard number 2... icon only...')
        // console.log(vizItem)
        return (
          <div
            // style={{ animationDelay: vizItem.animationDuration + 's' }} /// animate__infinite animate__infinite
            className={`${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated  animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              // background: storyData.uiStyle['super-main-bg-color'],
              // color: storyData.uiStyle['super-main-title-color'],
              // borderColor: storyData.uiStyle['super-highlight-color'],
              //width: htmlPreview == 1 ? '100% ' : '400px',
              padding: '1rem',
              width: '140px',
              height: '140px',
              margin: htmlPreview == 1 ? 'auto' : 'initial',
              //height: htmlPreview == 1 ? '500px ' : 'initial',
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>
            <img src={vizItem.cardImage} className="w-full mx-auto " />
          </div>
        )
      }


      // Text - Body Only
      else if (vizItem.cardLayout.id == 3) {
        return (
          <div
            // style={{ animationDelay: vizItem.animationDuration + 's' }}
            className={`viz-needs-info overflow-hidden  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              background: storyData.uiStyle['super-main-bg-color'],
              //color: storyData.uiStyle['super-main-title-color'],
              borderColor: storyData.uiStyle['super-highlight-color'],
              borderTop: '0px',
              //borderLeft: '8px solid',
              padding: '0rem',
              //borderTop: '0px',
              borderRadius: '1rem',
              width: htmlPreview == 1 ? '100% ' : '940px',
              //height: htmlPreview == 1 ? '200px ' : 'initial',
              // height: '340px',
              borderSize: '2px',
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>

            <div className="grid grid-cols-1 gap-2">
              <div className="p-10">
                <div
                  className="text-left text-2xl"
                  style={{
                    color: storyData.uiStyle['super-subtitle-color']
                  }}
                >
                  <div className="inline ">{vizItem.cardBody}</div>
                </div>
              </div>

            </div>

          </div>
        )
      }


      // Icon on Left
      else if (vizItem.cardLayout.id == 4) {
        return (
          <div
            // style={{ animationDelay: vizItem.animationDuration + 's' }}
            className={`viz-needs-info  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              background: storyData.uiStyle['super-main-bg-color'],
              color: storyData.uiStyle['super-main-title-color'],
              borderColor: storyData.uiStyle['super-highlight-color'],
              width: htmlPreview == 1 ? '100% ' : '400px',
              //height: htmlPreview == 1 ? '500px ' : 'initial',
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>
            <div className="grid grid-cols-5 gap-2">
              <div className="col-span-2">
                <img src={vizItem.cardImage} className="w-1/2 mx-auto mb-10" />
              </div>
              <div className="col-span-3">
                <div
                  className="text-left px-4 font-semibold text-2xl"
                  style={{
                    color: storyData.uiStyle['super-main-title-color']
                  }}
                >
                  {vizItem.cardTitle}
                </div>
                <div
                  className="text-left px-4 pb-4"
                  style={{
                    color: storyData.uiStyle['super-subtitle-color']
                  }}
                >
                  {vizItem.cardSubtitle}
                </div>
                <div
                  className="relative inline-flex text-left px-4 pb-8"
                  style={{
                    color: storyData.uiStyle['super-body-color']
                  }}
                >
                  {vizItem.cardBody}
                </div>
              </div>
            </div>
          </div>
        )
      }

      else if (vizItem.cardLayout.id == 5) {
        return (
          <div
            // style={{ animationDelay: vizItem.animationDuration + 's' }}
            className={`viz-needs-info  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              background: storyData.uiStyle['super-main-bg-color'],
              color: storyData.uiStyle['super-main-title-color'],
              //borderColor: storyData.uiStyle['super-highlight-color'],
              borderTop: '0px',
              width: htmlPreview == 1 ? '100% ' : '400px',
              //height: htmlPreview == 1 ? '500px ' : 'initial',
              //marginTop: htmlPreview == 1 ? '-20%' : '',
              padding: '0px',
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>
            <div
              className="text-left px-4 font-semibold text-2xl pt-10"
              style={{
                color: storyData.uiStyle['super-main-title-color']
              }}
            >
              <img src={vizItem.cardImage} className="w-1/3 mx-auto mb-10" />
            </div>
            <div className="w-full p-4 text-center mb-8 font-bold text-2xl"
              style={{
                background: storyData.uiStyle['super-highlight-color'],
              }}
            >
              {vizItem.cardTitle}
            </div>
            <div
              className="text-left px-8"
              style={{
                color: storyData.uiStyle['super-subtitle-color']
              }}
            >
              {vizItem.cardSubtitle}
            </div>
            <div
              className="relative inline-flex text-left px-8 pb-8 pt-4"
              style={{
                color: storyData.uiStyle['super-subtitle-color']
              }}
            >
              {vizItem.cardBody}
            </div>
          </div>
        )
      }


      else if (vizItem.cardLayout.id == 6) {
        return (
          <div
            // style={{ animationDelay: vizItem.animationDuration + 's' }}
            className={`viz-needs-info  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              background: storyData.uiStyle['super-main-bg-color'],
              color: storyData.uiStyle['super-main-title-color'],
              borderColor: storyData.uiStyle['super-highlight-color'],
              padding: '2rem',
              width: htmlPreview == 1 ? '100% ' : '400px',
              //marginTop: htmlPreview == 1 ? '-5%' : '',
              //height: htmlPreview == 1 ? '200px ' : 'initial',
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>
            <div className="grid grid-cols-5 gap-2 ">
              <div className="col-span-2">
                <img src={vizItem.cardImage} className="w-1/2 mx-auto mb-4" />
              </div>
              <div className="col-span-3">
                <div
                  className="text-left px-4 font-semibold text-2xl"
                  style={{
                    color: storyData.uiStyle['super-main-title-color']
                  }}
                >
                  {vizItem.cardTitle}
                </div>
                <div
                  className="text-left px-4 pb-4"
                  style={{
                    color: storyData.uiStyle['super-subtitle-color']
                  }}
                >
                  {vizItem.cardSubtitle}
                </div>

              </div>
            </div>
          </div>
        )
      }

      else if (vizItem.cardLayout.id == 7) {
        return (
          <div
            // style={{ animationDelay: vizItem.animationDuration + 's' }}
            className={`viz-needs-info  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              background: storyData.uiStyle['super-main-bg-color'],
              color: storyData.uiStyle['super-main-title-color'],
              //borderColor: storyData.uiStyle['super-highlight-color'],
              padding: '2.75rem',
              borderTop: '0px',
              borderRadius: '100%',
              width: '140px',
              height: '140px',
              borderSize: '2px',
              margin: htmlPreview == 1 ? 'auto' : 'initial',
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>
            <img src={vizItem.cardImage} className="w-full mx-auto" />
          </div>
        )
      }

      else if (vizItem.cardLayout.id == 8) {
        return (
          <div
            // style={{ animationDelay: vizItem.animationDuration + 's' }}
            className={`viz-needs-info  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              background: storyData.uiStyle['super-main-bg-color'],
              color: storyData.uiStyle['super-main-title-color'],
              //borderColor: storyData.uiStyle['super-highlight-color'],
              padding: '2rem',
              borderTop: '0px',
              borderRadius: '1rem',
              width: '140px',
              height: '140px',
              borderSize: '2px',
              //margin: 'auto',
              margin: htmlPreview == 1 ? 'auto' : 'initial',
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>
            <img src={vizItem.cardImage} className="w-full mx-auto" />
          </div>
        )
      }

      // Quote #1
      else if (vizItem.cardLayout.id == 9) {
        return (
          <div
            // style={{ animationDelay: vizItem.animationDuration + 's' }}
            className={`viz-needs-info    ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              background: storyData.uiStyle['super-main-bg-color'],
              color: storyData.uiStyle['super-main-title-color'],
              //borderColor: storyData.uiStyle['super-highlight-color'],
              padding: '3rem',
              borderTop: '0px',
              borderRadius: '1rem',
              width: htmlPreview == 1 ? '100% ' : '940px',
              // height: '340px',
              borderSize: '2px',
              //opacity: '.5',
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>

            <div className="grid grid-cols-1 gap-2">
              <div
                className="text-left text-2xl"
                style={{
                  color: storyData.uiStyle['super-subtitle-color']
                }}
              >
                <div className="text-6xl mr-4 font-semibold absolute l-0 t-o"
                  style={{
                    color: storyData.uiStyle['super-highlight-color']
                  }}
                >&quot;</div>
                <div className="inline ml-8"
                  style={{
                    color: storyData.uiStyle['super-main-subtitle-color'],
                  }}
                >{vizItem.cardBody}</div>
              </div>
              <div className="text-left mt-4">
                <div
                  className="text-left font-semibold text-2xl"
                  style={{
                    color: storyData.uiStyle['super-main-title-color']
                  }}
                >
                  {vizItem.cardTitle}
                </div>
                <div
                  className="text-left text-gray-200"
                  style={{
                    color: storyData.uiStyle['super-subtitle-color']
                  }}
                >
                  {vizItem.cardSubtitle}
                </div>
              </div>
            </div>

          </div>
        )
      }

      // Quote #2
      else if (vizItem.cardLayout.id == 10) {
        return (
          <div
            // style={{ animationDelay: vizItem.animationDuration + 's' }}
            className={`viz-needs-info  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              background: storyData.uiStyle['super-main-bg-color'],
              color: storyData.uiStyle['super-main-title-color'],
              borderColor: storyData.uiStyle['super-highlight-color'],
              borderTop: '0px',
              borderLeft: '8px solid',
              padding: '3rem',
              //borderTop: '0px',
              borderRadius: '1rem',
              width: htmlPreview == 1 ? '100% ' : '940px',
              // height: '340px',
              borderSize: '2px',
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>

            <div className="grid grid-cols-1 gap-2">
              <div
                className="text-left text-2xl"
                style={{
                  color: storyData.uiStyle['super-subtitle-color']
                }}
              >
                {/* <div className="text-6xl mr-4 font-semibold absolute l-0 t-o" 
                style={{
                  color: storyData.uiStyle['super-highlight-color']
                }}
                >&quot;</div>  */}
                <div className="inline ">{vizItem.cardBody}</div>
              </div>
              <div className="text-left mt-4">
                <div
                  className="text-left font-semibold text-2xl"
                  style={{
                    color: storyData.uiStyle['super-main-title-color']
                  }}
                >
                  {vizItem.cardTitle}
                </div>
                <div
                  className="text-left "
                  style={{
                    color: storyData.uiStyle['super-subtitle-color']
                  }}
                >
                  {vizItem.cardSubtitle}
                </div>
              </div>
            </div>

          </div>
        )
      }




      // Quote Style #3
      else if (vizItem.cardLayout.id == 11) {
        return (
          <div
            // style={{ animationDelay: vizItem.animationDuration + 's' }}
            className={`viz-needs-info overflow-hidden  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              background: storyData.uiStyle['super-main-bg-color'],
              //color: storyData.uiStyle['super-main-title-color'],
              borderColor: storyData.uiStyle['super-highlight-color'],
              borderTop: '0px',
              //borderLeft: '8px solid',
              padding: '0rem',
              //borderTop: '0px',
              borderRadius: '1rem',
              width: htmlPreview == 1 ? '100% ' : '940px',
              // height: '340px',
              borderSize: '2px',
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>

            <div className="grid grid-cols-6 gap-2">
              <div className="col-span-2 p-16"
                style={{
                  backgroundColor: storyData.uiStyle['nav-button-bg-on'],
                  color: storyData.uiStyle['nav-button-text-on'],
                  //   backgroundColor: storyData.uiStyle['nav-button-bg-on'],
                  // color:  storyData.uiStyle['nav-button-text-on']
                }}
              >
                <div className="text-left">
                  <div
                    className="text-left font-semibold text-2xl "
                    style={{
                      color: storyData.uiStyle['super-title-color']
                    }}
                  >
                    {vizItem.cardTitle}
                  </div>
                  <div
                    className="text-left "
                    style={{
                      color: storyData.uiStyle['super-subtitle-color']
                    }}
                  >
                    {vizItem.cardSubtitle}
                  </div>
                </div>
              </div>

              <div className="col-span-4 p-16">
                <div
                  className="text-left text-2xl"
                  style={{
                    color: storyData.uiStyle['super-body-color']
                  }}
                >
                  <div className="inline">{vizItem.cardBody}</div>
                </div>

              </div>
            </div>

          </div>
        )
      }




      // Quote Style #4
      else if (vizItem.cardLayout.id == 12) {
        return (
          <div
            // style={{ animationDelay: vizItem.animationDuration + 's' }}
            className={`viz-needs-info overflow-hidden  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              background: storyData.uiStyle['super-main-bg-color'],
              //color: storyData.uiStyle['super-main-title-color'],
              borderColor: storyData.uiStyle['super-highlight-color'],
              borderTop: '0px',
              //borderLeft: '8px solid',
              padding: '0rem',
              //borderTop: '0px',
              borderRadius: '1rem',
              width: htmlPreview == 1 ? '100% ' : '940px',
              // height: '340px',
              borderSize: '2px',
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>

            <div className="grid grid-cols-1 gap-2">
              <div className="px-16 pt-16">
                <div
                  className="text-left text-2xl"
                  style={{
                    color: storyData.uiStyle['super-body-color']
                  }}
                >
                  <div className="inline">{vizItem.cardBody}</div>
                </div>
              </div>
              <div className="px-16 py-10"
                style={{
                  backgroundColor: storyData.uiStyle['nav-button-bg-on'],
                  color: storyData.uiStyle['nav-button-text-on'],
                }}
              >
                <div className="text-left">
                  <div
                    className="text-left font-semibold text-2xl "
                    style={{
                      color: storyData.uiStyle['super-main-title-color']
                    }}
                  >
                    {vizItem.cardTitle}
                  </div>
                  <div
                    className="text-left"
                    style={{
                      color: storyData.uiStyle['super-subtitle-color']
                    }}
                  >
                    {vizItem.cardSubtitle}
                  </div>
                </div>
              </div>
            </div>

          </div>
        )
      }

      // Card - icon above the card - circle...?!
      else if (vizItem.cardLayout.id == 13) {
        return (
          <div
          // style={{scale: 2}}
          >
            <div
              className="text-left px-4 font-semibold text-2xl  pb-40"
              style={{
                color: storyData.uiStyle['super-main-title-color']
              }}
            >
              <img src={vizItem.cardImage} className="w-1/3 mx-auto" />
            </div>
            <div
              // style={{ animationDelay: vizItem.animationDuration + 's' }}
              className={`viz-needs-info  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
              style={{
                background: storyData.uiStyle['super-main-bg-color'],
                color: storyData.uiStyle['super-main-title-color'],
                //borderColor: storyData.uiStyle['super-highlight-color'],
                borderTop: '0px',
                width: htmlPreview == 1 ? '100% ' : '400px',
                marginTop: htmlPreview == 1 ? '-20%' : '',
                //height: htmlPreview == 1 ? '900px ' : 'initial',
                padding: '0px',
                animationDelay: vizItem.animationDuration + 's',
              }}
              onClick={() => activateSidebar(vizItem.guid)}>
              <div
                className="text-left  px-8 pt-8"
                style={{
                  color: storyData.uiStyle['super-subtitle-color']
                }}
              >
                {vizItem.cardTitle}
                {/* {vizItem.cardSubtitle} */}
              </div>
              <div
                className=" text-left px-8 pb-8 pt-4"
                style={{
                  color: storyData.uiStyle['super-subtitle-color']
                }}
              >
                {vizItem.cardBody}
              </div>
            </div>
          </div>
        )
      }

      // Card - icon above the card with circle
      else if (vizItem.cardLayout.id == 14) {
        return (
          <div
            className={` ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}
          >
            <div
              className=" p-4 font-semibold text-2xl h-40 rounded-full w-40  mx-auto mb-10 "
              style={{
                color: storyData.uiStyle['super-main-title-color'],
                background: storyData.uiStyle['super-main-bg-color'],
              }}
            >
              <img src={vizItem.cardImage} className="w-3/4 pt-4 mx-auto  " />
            </div>
            <div
              // style={{ animationDelay: vizItem.animationDuration + 's' }}
              className={`viz-needs-info `}
              style={{
                background: storyData.uiStyle['super-main-bg-color'],
                color: storyData.uiStyle['super-main-title-color'],
                //borderColor: storyData.uiStyle['super-highlight-color'],
                borderTop: '0px',
                width: htmlPreview == 1 ? '100% ' : '400px',
                padding: '0px',
                animationDelay: vizItem.animationDuration + 's',
                //scale: 2,
              }}>
              <div
                className="text-left  px-8 pt-8"
                style={{
                  color: storyData.uiStyle['super-subtitle-color']
                }}
              >
                {vizItem.cardTitle}
                {/* {vizItem.cardSubtitle} */}
              </div>
              <div
                className=" text-left px-8 pb-8 pt-4"
                style={{
                  color: storyData.uiStyle['super-subtitle-color']
                }}
              >
                {vizItem.cardBody}
              </div>
            </div>
          </div>
        )
      }


      // Card - icon above the card with circle that uses highlight color as bg...
      else if (vizItem.cardLayout.id == 21) {
        return (
          <div
            className={` ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}
          >
            <div
              className=" p-4 font-semibold text-2xl h-40 rounded-full w-40  mx-auto mb-10 "
              style={{
                color: storyData.uiStyle['super-main-title-color'],
                background: storyData.uiStyle['super-highlight-color'],
              }}
            >
              <img src={vizItem.cardImage} className="w-3/4 pt-4 mx-auto  " />
            </div>
            <div
              // style={{ animationDelay: vizItem.animationDuration + 's' }}
              className={`viz-needs-info `}
              style={{
                background: storyData.uiStyle['super-main-bg-color'],
                color: storyData.uiStyle['super-main-title-color'],
                //borderColor: storyData.uiStyle['super-highlight-color'],
                borderTop: '0px',
                width: htmlPreview == 1 ? '100% ' : '400px',
                padding: '0px',
                animationDelay: vizItem.animationDuration + 's',
                //scale: 2,
              }}>
              <div
                className="text-left px-8 pt-8 text-3xl font-semibold"
                style={{
                  //color: storyData.uiStyle['super-subtitle-color']
                  color: storyData.uiStyle['super-main-title-color'],
                }}
              >
                {vizItem.cardTitle}
                {/* {vizItem.cardSubtitle} */}
              </div>
              <div
                className=" text-left px-8 pb-8 pt-4"
                style={{
                  color: storyData.uiStyle['super-subtitle-color']
                }}
              >
                {vizItem.cardBody}
              </div>
            </div>
          </div>
        )
      }

      // Title with body
      else if (vizItem.cardLayout.id == 15) {
        return (
          <div
            // style={{ animationDelay: vizItem.animationDuration + 's' }}
            className={`viz-needs-info overflow-hidden  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              background: storyData.uiStyle['super-main-bg-color'],
              //color: storyData.uiStyle['super-main-title-color'],
              borderColor: storyData.uiStyle['super-highlight-color'],
              borderTop: '0px',
              //borderLeft: '8px solid',
              padding: '0rem',
              //borderTop: '0px',
              borderRadius: '1rem',
              width: htmlPreview == 1 ? '100% ' : '940px',
              //height: htmlPreview == 1 ? '200px ' : 'initial',
              // height: '340px',
              borderSize: '2px',
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>

            <div className="grid grid-cols-1 gap-2">
              <div className="px-10 py-10">
                <div
                  className="text-left text-2xl"
                  style={{
                    color: storyData.uiStyle['super-subtitle-color']
                  }}
                >
                  <div className="  font-semibold pb-2">{vizItem.cardTitle}</div>
                  <div className=" ">{vizItem.cardBody}</div>
                </div>
              </div>

            </div>

          </div>
        )
      }


      // Icon on left #2 - text on right
      else if (vizItem.cardLayout.id == 16) {
        return (
          <div
            // style={{ animationDelay: vizItem.animationDuration + 's' }}
            className={`viz-needs-info overflow-hidden  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              background: storyData.uiStyle['super-main-bg-color'],
              //color: storyData.uiStyle['super-main-title-color'],
              borderColor: storyData.uiStyle['super-highlight-color'],
              borderTop: '0px',
              //borderLeft: '8px solid',
              padding: '0rem',
              //borderTop: '0px',
              borderRadius: '1rem',
              width: htmlPreview == 1 ? '100% ' : '400px',
              // height: '340px',
              borderSize: '2px',
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>

            <div className="grid grid-cols-6 gap-2">
              <div className="col-span-2 p-16"
                style={{
                  backgroundColor: storyData.uiStyle['nav-button-bg-on'],
                  color: storyData.uiStyle['nav-button-text-on'],
                  //   backgroundColor: storyData.uiStyle['nav-button-bg-on'],
                  // color:  storyData.uiStyle['nav-button-text-on']
                }}
              >
                <div className="text-left">
                  <img src={vizItem.cardImage} className="w-full mx-auto mb-10" />
                </div>
              </div>

              <div className="col-span-4 p-16">
                <div
                  className="text-left text-2xl"
                  style={{
                    color: storyData.uiStyle['super-subtitle-color']
                  }}
                >
                  {/* <div className="text-6xl mr-4 font-semibold absolute l-0 t-o" 
    style={{
      color: storyData.uiStyle['super-highlight-color']
    }}
    >&quot;</div>  */}
                  <div
                    className="text-left font-semibold text-2xl "
                    style={{
                      color: storyData.uiStyle['super-main-title-color']
                    }}
                  >
                    {vizItem.cardTitle}
                  </div>
                  <div
                    className="text-left pb-4"
                    style={{
                      color: storyData.uiStyle['super-subtitle-color']
                    }}
                  >
                    {vizItem.cardSubtitle}
                  </div>

                  <div className="  "
                    style={{
                      color: storyData.uiStyle['super-body-color']
                    }}
                  >{vizItem.cardBody}</div>
                </div>

              </div>
            </div>

          </div>
        )
      }



      // Chat bubble - arrow on left
      else if (vizItem.cardLayout.id == 17) {

        //console.log(storyData.uiStyle)

        return (
          <div
            className={`viz-needs-info overflow-hidden  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              background: 'transparent', //storyData.uiStyle['super-main-bg-color'],
              color: storyData.uiStyle['super-main-title-color'],
              borderTop: '0px',
              padding: '0rem',
              //borderTop: '0px',
              borderRadius: '1rem',
              width: htmlPreview == 1 ? '100% ' : '400px',
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>

            <div className="flex items-center justify-start">
              <div className="w-3 overflow-hidden">
                <div
                  style={{
                    backgroundColor: storyData.uiStyle['super-main-bg-color'],
                    color: storyData.uiStyle['super-subtitle-color'],
                  }}
                  className="h-4 rotate-45 transform origin-bottom-right rounded-sm"></div>
              </div>
              <div
                style={{
                  backgroundColor: storyData.uiStyle['super-main-bg-color'],
                  color: storyData.uiStyle['super-subtitle-color'],
                }}
                className="p-8 my-6 rounded-lg flex-1">
                {vizItem.cardBody}
              </div>
            </div>

          </div>
        )
      }


      // Chat bubble - arrow on right
      else if (vizItem.cardLayout.id == 18) {
        return (
          <div
            className={`viz-needs-info overflow-hidden  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              background: 'transparent', //storyData.uiStyle['super-main-bg-color'],
              color: storyData.uiStyle['super-main-title-color'],
              borderTop: '0px',
              padding: '0rem',
              //borderTop: '0px',
              borderRadius: '1rem',
              width: htmlPreview == 1 ? '100% ' : '400px',
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>

            <div className="flex items-center justify-start">
              <div
                style={{
                  backgroundColor: storyData.uiStyle['super-main-bg-color'],
                  color: storyData.uiStyle['super-subtitle-color'],
                }}
                className="p-8 my-6 rounded-lg flex-1">
                {vizItem.cardBody}
              </div>
              <div className="w-3 overflow-hidden ">
                <div
                  style={{
                    backgroundColor: storyData.uiStyle['super-main-bg-color'],
                    color: storyData.uiStyle['super-subtitle-color'],
                  }}
                  className="h-4 rotate-45 transform origin-top-left rounded-sm"></div>
              </div>
            </div>

          </div>
        )
      }



      // Interactive - Icon click to reveal underneath
      if (vizItem.cardLayout.id == 19) {

        const [show, setShow] = useState(false);

        return (
          <div
            // style={{ animationDelay: vizItem.animationDuration + 's' }}
            className={`viz-needs-info  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              background: storyData.uiStyle['super-main-bg-color'],
              color: storyData.uiStyle['super-main-title-color'],
              borderColor: storyData.uiStyle['super-highlight-color'],
              //width: htmlPreview == 1 ? '100% ' : '200px',
              width: show == 1 ? '400px' : '160px',
              //height: htmlPreview == 1 ? '500px ' : 'initial',
              //marginTop: htmlPreview == 1 ? '-15%' : '',
              animationDelay: vizItem.animationDuration + 's',
              //background: 'transparent',
              borderTop: show == 1 ? '4px solid' : '0px solid',
              padding: show == 1 ? '2rem' : '0rem',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>
            {!show ?
              <div
                className="text-left px-0 font-semibold text-3xl mb-2 pt-1 cursor-pointer"

                onClick={() => setShow(!show)}
              >
                <img src={vizItem.cardImage} className="w-1/3 mx-auto mt-4" />
                <div
                  className="text-center text-lg font-base px-4 pt-4 pb-4"
                  style={{
                    color: storyData.uiStyle['super-subtitle-color']
                  }}
                >
                  {vizItem.cardSubtitle}
                </div>
              </div>
              :
              <div className="animate__animated anmiate_fadeIn"
                onClick={() => setShow(!show)}
                style={{
                  background: storyData.uiStyle['super-main-bg-color'],
                  color: storyData.uiStyle['super-main-title-color'],
                  borderColor: storyData.uiStyle['super-highlight-color'],
                }}
              >
                <div
                  className="text-left px-4 font-semibold text-3xl mb-2"
                  style={{
                    color: storyData.uiStyle['super-main-title-color']
                  }}
                >
                  {vizItem.cardTitle}
                </div>
                <div
                  className="relative inline-flex text-left px-4 pb-8 mt-2"
                  style={{
                    color: storyData.uiStyle['super-subtitle-color']
                  }}
                >
                  {/* {vizItem.cardBody} */}
                  {vizItem.cardBody.replaceAll('*', '•')}
                </div>
              </div>
            }
          </div>
        )
      }


      // Interactive - Flip Card
      if (vizItem.cardLayout.id == 20) {

        const [isFlipped, setFlipped] = useState(false);

        const handleFlip = () => {
          setFlipped(!isFlipped);
        };


        return (
          <div
            className={`flip-card ${isFlipped ? "flipped" : ""
              }`}
          >
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <div className="card-content">
                  FRONT INFO HERE...
                </div>
                <button
                  className="flip-button"
                  onClick={handleFlip}
                >
                  Flip
                </button>
              </div>
              <div className="flip-card-back">
                <div className="card-content">
                  WELCOME TO THE BACK!
                </div>
                <button
                  className="flip-button"
                  onClick={handleFlip}
                >
                  Flip
                </button>
              </div>
            </div>
          </div>
        )
      }


      if (vizItem.cardLayout.id == 22) {

        const [show, setShow] = useState(false);

        return (
          <div
            // style={{ animationDelay: vizItem.animationDuration + 's' }}
            className={`viz-needs-info  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              background: storyData.uiStyle['nav-region-bg'],
              color: storyData.uiStyle['nav-button-text-on'],
              borderColor: storyData.uiStyle['super-highlight-color'],
              //width: htmlPreview == 1 ? '100% ' : '200px',
              width: show == 1 ? '400px' : '860px',
              //height: htmlPreview == 1 ? '500px ' : 'initial',
              //marginTop: htmlPreview == 1 ? '-15%' : '',
              animationDelay: vizItem.animationDuration + 's',
              //background: 'transparent',
              borderTop: show == 1 ? '4px solid' : '0px solid',
              padding: show == 1 ? '2rem' : '2rem',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>
            {!show ?
              <div
                className="text-left px-0 font-normal text-3xl mb-2 pt-1 cursor-pointer"

              //onClick={() => setShow(!show)}
              //onClick={() => emitOnChange({ id: vizItem.jumpSceneButton.sceneId })}

              >
                <img src={vizItem.cardImage} className="w-1/3 mx-auto mt-4" />

                <div
                  className="text-center px-4 font-normal text-3xl mb-2"
                  style={{
                    color: '#FF5200' //storyData.uiStyle['super-main-title-color']
                  }}
                >
                  {vizItem.cardTitle}
                </div>
                <div
                  className="w-full text-lg text-center px-4 pb-8 mt-2 mx-auto"
                  style={{
                    color: storyData.uiStyle['super-main-body-color'] //storyData.uiStyle['super-subtitle-color']
                  }}
                >
                  {/* {vizItem.cardBody} */}
                  {vizItem.cardBody.replaceAll('*', '•')}
                </div>
                <div
                  className="text-center text-lg font-base px-4 pt-4 pb-4"
                  style={{
                    color: storyData.uiStyle['super-subtitle-color']
                  }}
                >


                  {vizItem.jumpSceneButtons != null ?
                    <div className="flex">
                      {vizItem.jumpSceneButtons.map((button, buttonIdx) => (
                        <div className="flex-auto p-4" key={button.name + 'index'}>
                          <div

                            role="button"
                            className={`bg-neutral-800
                  hover:bg-orange-600
                  rounded p-2
                  w-full mx-auto 
                  text-base
                  text-gray-200
                  transition duration-300 
                  `}
                            onClick={() => emitOnChange({ id: button.sceneId })}
                            style={{
                              backgroundColor: sessionData.activeIndex,
                              // == stepIdx ? storyData.uiStyle['nav-button-bg-on'] : storyData.uiStyle['nav-button-bg-off'],
                              color: sessionData.activeIndex,
                              // == stepIdx ? storyData.uiStyle['nav-button-text-on'] : storyData.uiStyle['nav-button-text-off'],
                              borderBottom: sessionData.activeIndex // == stepIdx ? '4px solid ' + storyData.uiStyle['nav-progress-meter-bg'] : '4px solid ' + storyData.uiStyle['nav-progress-meter']
                            }}
                          >
                            {button.name}
                          </div>
                        </div>
                      ))}
                    </div>
                    : null}



                </div>
              </div>
              :
              <div className="animate__animated anmiate_fadeIn"
                //onClick={() => emitOnChange({ id: 1 })}
                //onClick={() => emitOnChange({ id: vizItem.jumpSceneButton.sceneId })}
                style={{
                  background: storyData.uiStyle['super-main-bg-color'],
                  color: storyData.uiStyle['super-main-title-color'],
                  borderColor: storyData.uiStyle['super-highlight-color'],
                }}
              >

              </div>
            }
          </div>
        )
      }



    }



    // Button to navigate, but with the styles that are used in the Theme
    if (vizItem.cardLayout && vizItem.cardLayout.id == 23) {


      const [show, setShow] = useState(false);

      return (
        <div
          // style={{ animationDelay: vizItem.animationDuration + 's' }}
          className={`viz-needs-info  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
          style={{
            background: storyData.uiStyle['nav-region-bg'],
            color: storyData.uiStyle['nav-button-text-on'],
            borderColor: storyData.uiStyle['super-highlight-color'],
            //width: htmlPreview == 1 ? '100% ' : '200px',
            width: show == 1 ? '400px' : '860px',
            //height: htmlPreview == 1 ? '500px ' : 'initial',
            //marginTop: htmlPreview == 1 ? '-15%' : '',
            animationDelay: vizItem.animationDuration + 's',
            //background: 'transparent',
            borderTop: show == 1 ? '4px solid' : '0px solid',
            padding: show == 1 ? '2rem' : '2rem',
          }}
          onClick={() => activateSidebar(vizItem.guid)}>
          {!show ?
            <div
              className="text-left px-0 font-normal text-3xl mb-2 pt-1 cursor-pointer"

            //onClick={() => setShow(!show)}
            //onClick={() => emitOnChange({ id: vizItem.jumpSceneButton.sceneId })}

            >
              <img src={vizItem.cardImage} className="w-1/3 mx-auto mt-4" />

              <div
                className="text-center px-4 font-normal text-3xl mb-2"
                style={{
                  color: storyData.uiStyle['super-main-title-color']
                }}
              >
                {vizItem.cardTitle}
              </div>
              <div
                className="w-full text-lg text-center px-4 pb-8 mt-2 mx-auto"
                style={{
                  color: storyData.uiStyle['super-subtitle-color']
                }}
              >
                {/* {vizItem.cardBody} */}
                {vizItem.cardBody.replaceAll('*', '•')}
              </div>
              <div
                className="text-center text-lg font-base px-4 pt-4 pb-4"
                style={{
                  color: storyData.uiStyle['super-subtitle-color']
                }}
              >


                {vizItem.jumpSceneButtons != null ?
                  <div className="flex">
                    {vizItem.jumpSceneButtons.map((button, buttonIdx) => (
                      <div className="flex-auto p-4" key={button.name + 'index'}>
                        <div

                          role="button"
                          className={` 
                rounded p-2
                w-full mx-auto 
                transition duration-300 
                `}
                          onClick={() => emitOnChange({ id: button.sceneId })}
                          style={{
                            backgroundColor: storyData.uiStyle['nav-button-bg-on'],
                            // == stepIdx ? storyData.uiStyle['nav-button-bg-on'] : storyData.uiStyle['nav-button-bg-off'],
                            color: storyData.uiStyle['nav-button-text-on'],
                            // == stepIdx ? storyData.uiStyle['nav-button-text-on'] : storyData.uiStyle['nav-button-text-off'],
                            //borderBottom: sessionData.activeIndex // == stepIdx ? '4px solid ' + storyData.uiStyle['nav-progress-meter-bg'] : '4px solid ' + storyData.uiStyle['nav-progress-meter']
                          }}
                        >
                          {button.name}
                        </div>
                      </div>
                    ))}
                  </div>
                  : null}



              </div>
            </div>
            :
            <div className="animate__animated anmiate_fadeIn"
              //onClick={() => emitOnChange({ id: 1 })}
              //onClick={() => emitOnChange({ id: vizItem.jumpSceneButton.sceneId })}
              style={{
                background: storyData.uiStyle['super-main-bg-color'],
                color: storyData.uiStyle['super-main-title-color'],
                borderColor: storyData.uiStyle['super-highlight-color'],
              }}
            >

            </div>
          }
        </div>
      )
    }







    // Persona
    else if (vizType == 1) {

      // // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
      // // console.log(vizItem)

      const displayPersona = []
      const mediaAvailable = vizItem.media[0] != null ? true : false

      if (mediaAvailable) {
        displayPersona.image = vizItem.media[0].image
        displayPersona.personaRole = vizItem.media[0].personaRole
        displayPersona.personaName = vizItem.media[0].personaName
        displayPersona.personaDetail = vizItem.media[0].personaDetail
      }

      let personaLayout = 1

      if (vizItem.layout) {
        //vizItem['layout'].id = 1
        personaLayout = vizItem.layout.id
      }

      // console.log('LAYOUT...!')
      // console.log(personaLayout)


      if (!mediaAvailable) {
        return (
          <div className={`viz-needs-info  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__zoomIn" : "noshow"}`} onClick={() => activateSidebar(vizItem.guid)}>
            <h1>Person</h1>
            <div>Select Person component from right to configure</div>
            <div><img src="/media/icon/noun-1417899-FFFFFF.svg" className="w-1/2 mx-auto" /></div>
          </div>
        )
      }

      if (personaLayout == 1) {
        return (
          <div
            className={`vizoverlay persona-card  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>
            <div className="bg-no-repeat bg-bottom h-64 mx-auto"
              style={{
                backgroundImage: `url(` + displayPersona.image + `)`,
                backgroundSize: `contain`,
              }}
            ></div>
            <div
              //className="persona-card-body"
              className={`persona-card-body org-${storyData.name.replace(/\s+/g, '-').toLowerCase() || 'none'}`}
              style={{
                background: storyData.uiStyle['super-main-bg-color'],
                color: storyData.uiStyle['super-main-title-color'],
                borderColor: storyData.uiStyle['super-highlight-color'],
              }}
            >
              <div className="name"
                style={{
                  color: storyData.uiStyle['super-main-title-color']
                }}
              >
                {displayPersona.personaName}
              </div>
              <div className="role"
                style={{
                  color: storyData.uiStyle['super-subtitle-color']
                }}
              >
                {displayPersona.personaRole}
              </div>
              <div className="role description"
                style={{
                  color: storyData.uiStyle['super-body-color']
                }}
              >
                {displayPersona.personaDetail != undefined ? displayPersona.personaDetail.replaceAll('*', '•') : ''}
              </div>
            </div>
          </div>
        )
      }

      if (personaLayout == 2) {
        return (
          <div
            className={`vizoverlay persona-card  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              animationDelay: vizItem.animationDuration + 's',
              minHeight: '0px !important',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>
            <div className="persona-card-bodyxx"
              style={{
                background: storyData.uiStyle['super-main-bg-color'],
                color: storyData.uiStyle['super-main-title-color'],
                borderColor: storyData.uiStyle['super-highlight-color'],
                width: '500px',
                padding: '0rem',
                minHeight: '0px !important',
                borderRadius: '.5rem',
              }}
            >
              <div className="grid grid-cols-5 gap-2">
                <div className="col-span-2">
                  <div className="bg-no-repeat bg-bottom h-64 mx-auto"
                    style={{
                      backgroundImage: `url(` + displayPersona.image + `)`,
                      backgroundSize: `contain`,
                    }}
                  ></div>
                </div>
                <div className="col-span-3">
                  <div className="name pt-4"
                    style={{
                      color: storyData.uiStyle['super-main-title-color']
                    }}
                  >
                    {displayPersona.personaName}
                  </div>
                  <div className="role"
                    style={{
                      color: storyData.uiStyle['super-subtitle-color']
                    }}
                  >
                    {displayPersona.personaRole}
                  </div>
                  <div className="role description"
                    style={{
                      color: storyData.uiStyle['super-subtitle-color']
                    }}
                  >
                    {displayPersona.personaDetail}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

      if (personaLayout == 3) {
        return (
          <div
            className={`vizoverlay persona-card  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>
            <div className={`persona-card-body`}
              style={{
                background: storyData.uiStyle['super-secondary-bg-color'],
                color: storyData.uiStyle['super-list-icon-color'],
                borderColor: storyData.uiStyle['super-list-icon-color'] + ' !important',
                borderRadius: '100%',
                backgroundImage: `url(` + displayPersona.image + `)`,
                backgroundSize: `contain`,
                backgroundPositionX: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundPositionY: 'bottom',
                border: '10px solid',
                height: '320px',
              }}
            >
            </div>
          </div>
        )
      }

      if (personaLayout == 4) {
        return (
          <div
            className={`vizoverlay persona-card  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>
            <div className="persona-card-body"
              style={{
                background: storyData.uiStyle['super-secondary-bg-color'],
                color: storyData.uiStyle['super-list-icon-color'],
                borderColor: storyData.uiStyle['super-list-icon-color'] + ' !important',
                borderRadius: '100%',
                backgroundImage: `url(` + displayPersona.image + `)`,
                backgroundSize: `contain`,
                backgroundPositionX: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundPositionY: 'bottom',
                border: '10px solid',
                height: '320px',
              }}
            >
            </div>
            <div className="persona-card-body"
              style={{
                background: storyData.uiStyle['super-main-bg-color'],
                color: storyData.uiStyle['super-main-title-color'],
                //borderColor: storyData.uiStyle['super-highlight-color'],
                borderTop: '0px',
                minHeight: '20px',
                marginTop: '-20px',
              }}
            >
              <div className="name text-center"
                style={{
                  color: storyData.uiStyle['super-main-title-color'],
                  marginTop: '0px',
                  paddingTop: '2px',
                  paddingBottom: '2px',
                }}
              >
                {displayPersona.personaName}
              </div>
            </div>
          </div>
        )
      }

      if (personaLayout == 5) {
        return (
          <div
            className={`vizoverlay persona-card  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
            style={{
              animationDelay: vizItem.animationDuration + 's',
            }}
            onClick={() => activateSidebar(vizItem.guid)}>
            <div className="persona-card-body"
              style={{
                background: storyData.uiStyle['super-secondary-bg-color'],
                color: storyData.uiStyle['super-list-icon-color'],
                borderColor: storyData.uiStyle['super-list-icon-color'] + ' !important',
                borderRadius: '100%',
                backgroundImage: `url(` + displayPersona.image + `)`,
                backgroundSize: `contain`,
                backgroundPositionX: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundPositionY: 'bottom',
                border: '10px solid',
                height: '320px',
              }}
            >
            </div>
            <div className="persona-card-body"
              style={{
                background: storyData.uiStyle['super-main-bg-color'],
                color: storyData.uiStyle['super-main-title-color'],
                //borderColor: storyData.uiStyle['super-highlight-color'],
                borderTop: '0px',
                minHeight: '20px',
                marginTop: '-20px',
              }}
            >
              <div className="name text-center"
                style={{
                  color: storyData.uiStyle['super-main-title-color'],
                  marginTop: '0px',
                  paddingTop: '2px',
                  paddingBottom: '2px',
                }}
              >
                {displayPersona.personaName}
              </div>
              <div className="role text-center"
                style={{
                  color: storyData.uiStyle['super-subtitle-color']
                }}
              >
                {displayPersona.personaRole}
              </div>
            </div>
          </div>
        )
      }

    }

    // Mobile
    else if (vizType == 2) {

      const _imageGallery = useRef(null);
      const reactPlayerRef = useRef();

      //const [playState, setPlayState] = useState(item.videoAutoPlay || false)
      const [playState, setPlayState] = useState(vizItem.videoAutoPlay || false)


      // Listen for events related to the player (stop, play, seek)
      useVidListener((action) => {
        if (action.stop) {
          setPlayState(false)
        }
        if (action.play) {
          setPlayState(true)
        }
      })

      const _renderVideo = (item) => {

        return (
          <div>
            <ReactPlayer
              url={item.embedUrl}
              thumbnail={item.original.replace('.mov', '.jpg').replace("mp4", "jpg")}
              width='100%'
              height='auto'
              //playsinline={true}
              muted={sessionData.playModeActive != 1 ? true : false}

              onEnded={() => {
                // Go to next scene automatically if autoplay is enabled
                // TODO - add autoplay option
                emitOnChange({ id: sessionData.activeIndex + 1 })
              }}

              playing={playState}
              //playing={item.videoAutoPlay}
              //play={item.videoAutoPlay || false}

              onReady={(current) => {
                emitVid({ length: reactPlayerRef.current.getDuration() })
                //emitVid({ progress: progress.playedSeconds })
                // console.log('VID ----- READY')
                // console.log('is it play?....')
                // console.log(current.playing)
              }}

              onPlay={() => {
                //                console.log('VID ----- PLAY')
              }}

              ref={reactPlayerRef}
              onProgress={(progress) => {
                emitVid({ progress: progress.playedSeconds })
              }}

              controls
              config={{
                file: {
                  attributes: {
                    controlsList: "nofullscreen",
                  },
                },
              }}
            />
          </div>
        );
      };

      const displayScreenArray = []
      const imageStyle = {
        width: 400,
        animationDelay: vizItem.animationDuration + 's',
      }

      let showDots = false
      let showNav = false
      let showThumbs = true

      sceneData[sessionData.activeIndex].viznodes.forEach(element => {

        if (element.guid == vizItem.guid) {



          if (element.galleryNavigation) {

            // console.log('GALLERY NAVIGATION')
            // console.log(element.galleryNavigation)

            if (element.galleryNavigation.name == 'dots') {
              showDots = true
              showNav = false
              showThumbs = false
            }
            if (element.galleryNavigation.name == 'arrows') {
              showDots = false
              showNav = true
              showThumbs = false
            }
            if (element.galleryNavigation.name == 'thumbs') {
              showDots = false
              showNav = false
              showThumbs = true
            }
          }



          if (element.id == 2) {
            element.media.forEach(elementImg => {
              if (elementImg.image.split('.').pop() == 'mov' || elementImg.image.split('.').pop() == 'mp4') {
                displayScreenArray.push(
                  {
                    embedUrl: elementImg.image,
                    videoAutoPlay: element.videoAutoPlay || false, //elementImg.videoAutoPlay,
                    original: elementImg.image.replace('mov', 'jpg').replace('mp4', 'jpg'),
                    thumbnail: elementImg.image.replace('mov', 'jpg').replace('mp4', 'jpg'),
                    renderItem: _renderVideo,
                  })
              }
              else {
                displayScreenArray.push(
                  {
                    original: elementImg.image,
                    thumbnail: elementImg.image,
                    thumbnailClass: 'someClassName',
                  })
              }
            })

            imageStyle.width = element.size.width
          }
        }
      });


      return (
        <>

          {(displayScreenArray.length == 0 ?
            <div className={`viz-needs-info  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animat  e__zoomIn" : "noshow"}`}
              onClick={() => activateSidebar(vizItem.guid)}
            >
              <h1>Add Media</h1>
              <div>Configure Gallery Component to add content</div>
              <div><img src="/media/icon/noun-1417899-FFFFFF.svg" className="w-1/2 mx-auto" /></div>
            </div>
            : (

              <div
                className={`vizoverlay device-phone-card zone-interactive ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + vizItem.animation.name : "noshow"}`}
                style={imageStyle}
              >
                <ImageGallery
                  items={displayScreenArray}
                  showPlayButton={false}
                  infinite={false}
                  ref={_imageGallery}
                  bulletClass={"gallery-bullet"}

                  showBullets={showDots}
                  showNav={showNav}
                  showThumbnails={vizItem.videoAutoPlay ? false : showThumbs}

                //autoPlay={true}
                //showVideo={true}
                />

              </div>
            )
          )}
        </>
      )
    }

    // Desktop
    else if (vizType == 3) {
      const displayScreenLocation = "/media/screen/" + displayImage + ".png";

      return (
        <div className={`vizoverlay device-desktop-card ${thisIndex == sessionData.activeIndex ? "animate__animated animate__fadeInUp" : "noshow"}`}>
          <div>
            <img src={displayScreenLocation} />
          </div>
        </div>
      )
    }

    // Image
    else if (vizType == 4) {
      const displayPersona = []
      const mediaAvailable = vizItem.media[0] != null ? true : false

      if (mediaAvailable) {
        displayPersona.image = vizItem.media[0].image
        displayPersona.guid = vizItem.guid
      }

      const imageStyle = {
        width: vizItem.size.width,
      }

      let animationStyle = ''
      if (vizItem.animation) {
        animationStyle = vizItem.animation.name
      }

      return (
        <>
          {mediaAvailable ?
            <div
              style={{ animationDelay: vizItem.animationDuration + 's' }}
              className={`vizoverlay persona-card ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + animationStyle : "noshow"}`}
              onClick={() => {
                activateSidebar(displayPersona.guid)
                if (vizItem.url) {
                  window.open(vizItem.url, '_blank');
                }
              }}
            >
              <div>
                <img src={displayPersona.image} className="transparent" style={imageStyle} id={'soloimg' + displayPersona.guid} />
              </div>
            </div>
            :
            (
              <div className={`viz-needs-info  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__zoomIn" : "noshow"}`}
                onClick={() => {
                  activateSidebar(vizItem.guid)
                  if (vizItem.url) {
                    window.open(vizItem.url, '_blank');
                  }
                }}
              >
                <h1>Image</h1>
                <div>Select Image component from right to add media</div>
                <div><img src="/media/icon/noun-1417899-FFFFFF.svg" className="w-1/2 mx-auto" /></div>
              </div>

            )}
        </>
      )
    }



    else if (vizType == 5) {
      const displayPersona = []
      const mediaAvailable = true //vizItem.content != null ? true : false
      displayPersona.image = '/media/icon/noun-link-1721082-FFFFFF.svg'
      displayPersona.guid = vizItem.guid
      const imageStyle = {
        width: vizItem.size.width,
      }
      let animationStyle = ''
      if (vizItem.animation) {
        animationStyle = vizItem.animation.name
      }
      return (
        <>
          {mediaAvailable ?
            <div
              style={{ animationDelay: vizItem.animationDuration + 's' }}
              className={`vizoverlay card-link ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + animationStyle : "noshow"}`}
              onClick={() => {
                window.open(vizItem.content, '_blank');
              }}
            >
              <div className="flex">
                <div className="flex-none w-12 h-12 mr-4">
                  <img src={displayPersona.image} className="transparent" style={imageStyle} id={'soloimg' + displayPersona.guid} />
                </div>
                <div className="flex text-gray-100 text-xl mt-2">
                  {vizItem.label}
                </div>
              </div>
            </div>
            :
            (
              <div className={`viz-needs-info  ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__zoomIn" : "noshow"}`}>
                <h1>URL</h1>
                <div>Set the URL that you would like to use in the right-hand panel</div>
                <div><img src="/media/icon/noun-1417899-FFFFFF.svg" className="w-1/2 mx-auto" /></div>
              </div>

            )}
        </>
      )
    }


    // SVG animation example
    else if (vizType == 6) {

      let animationStyle = ''
      if (vizItem.animation) {
        animationStyle = vizItem.animation.name || 'none'
      }

      if (vizItem.url != '') {
        return (
          <div
            // className={`vizoverlay allowinteract device-desktop-card ${thisIndex == sessionData.activeIndex ? "animate__animated animate__fadeInUp" : "noshow"}`}

            style={{ animationDelay: vizItem.animationDuration + 's' }}
            className={`vizoverlay device-desktop-card  allowinteract ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + animationStyle : "noshow"}`}
            onClick={() => {
              window.open(vizItem.content, '_blank');
            }}
          >

            {vizItem.url.includes('tableau') ?
              <TableauEmbed sourceUrl={vizItem.url} height={vizItem.size.height} width={vizItem.size.width} toolbar={"hidden"} />
              :
              <iframe src={vizItem.url} height={vizItem.size.height} width={vizItem.size.width} frameBorder="0" title="Web Content" allowFullScreen={true}></iframe>
            }


            {/* <GoogleDocsViewer
        width="100%"
        height="600px"
        fileUrl={'https://docs.google.com/presentation/d/1SMOGst0f331bQF-WbbP_6WLz6_76cnOS-21y2RL-4Vk/edit?usp=sharing'}
      /> */}
          </div>
        )
      }
      else {
        <div className={`vizoverlay allowinteract device-desktop-card ${thisIndex == sessionData.activeIndex ? "animate__animated animate__fadeInUp" : "noshow"}`}>
          <h2>Please set URL</h2>
        </div>
      }

    }
    else if (vizType == 7) {

      const imageStyle = {
        width: vizItem.size.width,
      }

      let animationStyle = ''
      if (vizItem.animation) {
        animationStyle = vizItem.animation.name
      }

      return (
        <div style={{ animationDelay: vizItem.animationDuration + 's' }} className={`vizoverlay persona-card-BAK ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + animationStyle : "noshow"}`}
          onClick={() => {
            activateSidebar(vizItem.guid)
          }}
        >
          <div className="card-free-text-clear " style={imageStyle} >
            <div dangerouslySetInnerHTML={{ __html: vizItem.content }}></div>
          </div>
        </div>
      )
    }



    //fadeInAndOut-1-10
    else if (vizType == 8) {
      const displayScreenLocation = "/media/notifications/" + displayImage + ".png";

      return (
        <div className={`vizoverlay device-img-card ${thisIndex == sessionData.activeIndex ? "animate__animated animate__fadeInUp" : "noshow"}`}>
          <div>
            <img src={displayScreenLocation} />
          </div>
        </div>
      )
    }

    else if (vizType == 9 || vizType == 10) {

      //      console.log(vizItem)

      const imageStyle = {
        width: vizItem.size.width,
        height: vizItem.size.height,
      }

      const styleOffset = {
        left: vizItem.size.width,
      }

      let animationStyle = ''
      if (vizItem.animation) {
        animationStyle = vizItem.animation
      }


      const DetailTab = (contentItem) => {
        const componentStyle = { left: 0 }

        return (
          <>

            <div className="flex h-24">
              <div className="flex-auto w-64 p-8">
                {vizItem.id == 9 ?
                  <img src={contentItem.vizItem.logoUrl}
                    className="float-left h-14" />
                  :
                  <img src={contentItem.vizItem.logoUrl}
                    className="h-96 absolute bottom-0 right-0" />
                }
                <h2 className="text-4xl font-semibold text-gray-800">{contentItem.vizItem.name}</h2>
              </div>
              <div className="flex-initial w-14 h-14 mr-4">

              </div>
            </div>


            <Tabs>
              {contentItem.vizItem.items.map((item, index) => (
                <div className="px-8">
                  <TabPanel>
                    {/* <SubTab item={item}></SubTab> */}
                    {/* <h1>{item.type}</h1>
                    <h1>{item.name}</h1>*/}

                    {item.type == 'video' ? (
                      <div className="master-video">
                        <ReactPlayer url={item.content}
                          width='600'
                          controls={true}
                          height='100%'
                        // onEnded={() => {
                        //   console.log('VIDEO ENDED!')
                        //  }}
                        />
                      </div>
                    ) :
                      null
                    }
                    {item.type == 'text' ? (
                      // <div className="h-96">
                      <p className="text-2xl mt-12 leading-12">{contentItem.vizItem.description}</p>
                      // </div>
                    ) :
                      null
                    }
                    {item.type == 'text-full' ? (
                      // <div className="h-96">
                      <div className="text-2xl mt-12 leading-relaxed" dangerouslySetInnerHTML={{ __html: item.content }} />
                      // </div>
                    ) :
                      null
                    }
                  </TabPanel>
                </div>
              ))}
              <TabList>
                {contentItem.vizItem.items.map((item, index) => (
                  <Tab>{item.name}</Tab>
                ))}
              </TabList>
            </Tabs>
          </>
        )
      }

      // const [activeCard, setActiveCard] = useState(null)
      // const [detailViewOpen, setDetailViewOpen] = useState(false)

      const Card = ({ title, text, index, vizItem, vizParent }) => {
        const [expanded, setExpanded] = useState(false);

        // console.log('GOT PARENT...')
        // console.log(vizParent)

        const toggleExpanded = () => {
          //          console.log('toggleExpanded')
          const cards = document.querySelectorAll('.card');
          if (!expanded) {
            // setActiveSubTab(0);
            // setActiveTab(index);

            cards.forEach(card => {
              if (card.id !== 'card' + index) {
                card.classList.add('hidden');
              }
            });
          }
          else {
            cards.forEach(card => {
              card.classList.remove('hidden');
            });
          }
          setExpanded(!expanded)
        };

        return (
          <>
            {expanded ? (
              // Card - detail view
              <>
                <div id={'card' + index} className={`card h-96 ${expanded ? 'expanded animate__animated animate__fadeIn' : ''}     `} >
                  <button className="button absolute right-2 top-0 w-14 h-14"
                    onClick={toggleExpanded}
                  >
                    <img src={'/media/icon/noun-close-1256611.svg'} className='w-10 opacity-50 hover:opacity-80 absolute ' />
                  </button>
                  <DetailTab vizItem={vizItem}></DetailTab>
                </div>
              </>
            ) : (
              // Card - grid style
              <div id={'card' + index} className={`card group ${expanded ? 'disabled-button' : ''}  ${index == 0 & vizParent.id == 10 ? 'grid-span-col-1-6' : ''}    `}
                onClick={(ev) => {
                  ev.stopPropagation();
                  toggleExpanded();
                }}>

                {vizParent.id == 9 ?
                  // ISV Card
                  <div className="bg-gray-800 ">
                    <div className="flex h-24">
                      <div className="flex-auto w-64 p-8">
                        <h2 className="text-2xl font-semibold text-white">{vizItem.name}</h2>
                      </div>
                      <div className="flex-initial w-14 h-14 mr-4">
                        <img src={vizItem.logoIconUrl} className="w-12 mt-4 mr-4" />
                      </div>
                    </div>
                    <div className="col-span-5 row-start-2 leading-relaxed  bg-gray-700  h-96 text-gray-100 h-8 text-xl px-8 pt-4">
                      {vizItem.description}
                    </div>
                  </div>
                  :
                  // Org Chart Card
                  <div className="bg-gray-100 group-hover:bg-white transition-all">
                    <div className="pt-4 overflow-hidden">
                      <img src={vizItem.logoIconUrl} className="w-48 mx-auto  " />
                    </div>
                    <div className="bg-gray-800 p-8">
                      <div className="w-64 h-24">
                        <h2 className="text-2xl font-semibold text-white text-center"> {vizItem.name}</h2>
                      </div>
                    </div>
                  </div>
                }

              </div>
            )}
          </>
        );
      };

      if (thisIndex == sessionData.activeIndex) {
        return (
          <>
            <div style={{ animationDelay: vizItem.animationDuration + 's ', height: vizItem.size.height, width: vizItem.size.width }} className={`vizoverlay ${vizItem.id == 9 ? 'master-detail-card' : ('master-detail-card-nobg')} ${thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__" + animationStyle : " noshow"}`}>

              <main className="min-h-full place-items-center py-20" style={styleOffset}>
                <div className="card-grid" style={{ height: vizItem.size.height, width: vizItem.size.width }} >
                  {vizItem.content.map((item, index) => (
                    <Card key={index} title={item.name} text={item.description} index={index} vizItem={item} vizParent={vizItem} />
                  ))}
                </div>
              </main>
            </div>
          </>
        )
      }
      else {
        return (null)
      }
    }


    else if (vizType == 11) {
      //const displayScreenLocation = "./media/notifications/" + displayImage + ".png";

      return (
        <div className={`vizoverlay device-live-card ${thisIndex == sessionData.activeIndex ? "animate__animated animate__fadeInUp" : "noshow"}`}>
          <div>
            Time is very valuable, nobody wants to wait for a technician.  Its a one-stop software and it makes us work efficiently.
          </div>
        </div>
      )
    }


    else if (vizType == 12) {
      return (
        <div style={{ height: "auto", margin: "0 auto", maxWidth: 256, width: "100%", background: 'white', padding: '16px', borderRadius: ".5rem" }}>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={window.location.href}
            viewBox={`0 0 256 256`}
          />
        </div>
      )
    }


    else if (vizType == 13) {
      return (
        <svg width="505" height="5" viewBox="0 0 505 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.99805 2.83936L502.998 2.83936" className="animate-line-dashed" stroke="#61dafb" stroke-width="4" stroke-linecap="round" />
        </svg>
      )
    }

    // MFG - circle animation
    else if (vizType == 14) {
      return (
        <div id="circle-orbit-container" className={thisIndex == sessionData.activeIndex || thisIndex == -1 ? "animate__animated animate__zoomIn  animate__delay-2s" : "noshow"}  >
          <div id="outer-orbit">
          </div>
        </div>
      )
    }


    else {
      return (
        <div></div>
      )
    }
  }



  let vizLevel = [0, 0]

  switch (vizType) {
    case 1: // Persona
      vizLevel = [10, 10]
      break;
    case 5: // Link
      vizLevel = [20, 20]
      break;
    default:
      vizLevel = [0, 0]
  }


  // NEW!
  if (thisIndex == sessionData.activeIndex) {

    // console.log('OG pos data')
    // console.log(position)

    return (
      <group>
        <Billboard
          follow={vizItem.faceCamera == true ? true : false}
          lockX={false}
          lockY={false}
          lockZ={false} // Lock the rotation on the z axis (default=false)
        >
          <DraggableMesh
            geometry={<Box args={[1, 1, 1]} />}
            //position={[-3, 2, 0]}
            color="white"
            rotation={vizType == 14 ? [THREE.MathUtils.degToRad(90), 0, 0] : [0, 0, 0]}
            position={position} //{...bind()}
            //transform={true}
            scale={vizType == 9 || vizType == 10 ? 1.1 : vizItem.scale || 1}
            fullscreen
            modelPath="/components/b3-admin-arrows.glb"
            vizItem={vizItem}
            vizLevel={vizLevel}
            sessionData={sessionData}
            vizType={vizType}
            zIndexRange={vizLevel}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            setOrbitActive={setOrbitActive}
            htmlContent={<DynamicContent vizType={vizType}
              key={vizItem.guid + 'dynamiccontent'}
            ></DynamicContent>}
          //htmlContent={<div>hello!</div>}

          />

        </Billboard>
      </group>
    )
  }




  // if (thisIndex == sessionData.activeIndex) {
  //   return (
  //     <group>
  //       <Billboard
  //         follow={vizItem.faceCamera == true ? true : false}
  //         lockX={false}
  //         lockY={false}
  //         lockZ={false} // Lock the rotation on the z axis (default=false)
  //       >

  //         <Html
  //           rotation={vizType == 14 ? [THREE.MathUtils.degToRad(90), 0, 0] : [0, 0, 0]}
  //           position={position} //{...bind()}
  //           transform={true}
  //           scale={vizType == 9 || vizType == 10 ? 1.1 : vizItem.scale || 1}
  //           fullscreen
  //           zIndexRange={vizLevel} //vizType == 1 ? [10, 10] : [0, 0]}
  //           onPointerOver={(event) => hover(true)}
  //           onPointerOut={(event) => hover(false)}
  //         >

  //           <div
  //             className={`vizoverlay ${sessionData.playModeActive != 1 ? 'hover:bg-white/20 p-3 transition ease-in-out hover:scale-105  border-dashed hover:border-4 border-sky-300 rounded-lg cursor-pointer' : ''}`}
  //             style={{ userSelect: 'none' }}
  //           >
  //             <DynamicContent vizType={vizType}
  //               key={vizItem.guid + 'dynamiccontent'}
  //             ></DynamicContent>
  //           </div>

  //           {sessionData.playModeActive != 1 ?
  //             <div className="absolute z-50  w-full"
  //               style={{ userSelect: 'none' }}
  //             >
  //               <div className="w-44 mx-auto mt-4">
  //                 <div className="grid grid-cols-3 gap-0">
  //                   <div className="w-full h-14 "></div>
  //                   <div className="w-full h-14 bg-zinc-900/25 hover:bg-zinc-900/50 rounded-t-lg cursor-pointer"
  //                     onClick={() =>
  //                       emitCount({ uuid: vizItem.guid, vizItem: vizItem, x: 0, y: 3, z: 0 })}
  //                   >
  //                     <img src="/media/icon/noun-up-arrow-1035685-FFFFFF.svg" className="h-8 mx-auto mt-2" />
  //                   </div>
  //                   <div className="w-full h-14 "></div>
  //                   <div className="w-full h-14 bg-zinc-900/25 hover:bg-zinc-900/50 rounded-l-lg cursor-pointer"
  //                     onClick={() =>
  //                       emitCount({ uuid: vizItem.guid, vizItem: vizItem, x: 3, y: 0, z: 0 })}
  //                   >
  //                     <img src="/media/icon/noun-left-arrow-1035682-FFFFFF.svg" className="h-8 mx-auto mt-3" />
  //                   </div>
  //                   <div className="w-full h-14 ">
  //                     <div className="grid grid-cols-3 gap-0 bg-zinc-900/25 rounded-none">
  //                       <div className="w-full h-5 "></div>
  //                       <div className="w-full h-5   hover:bg-zinc-900/50 rounded-sm cursor-pointer"
  //                         onClick={() =>
  //                           emitCount({ uuid: vizItem.guid, vizItem: vizItem, x: 0, y: 1, z: 0 })}
  //                       >
  //                         <img src="/media/icon/noun-up-arrow-1035685-FFFFFF.svg" className="h-fit p-1 mx-auto" />
  //                       </div>
  //                       <div className="w-full h-5 "></div>
  //                       <div className="w-full h-5  hover:bg-zinc-900/50 rounded-sm cursor-pointer"
  //                         onClick={() =>
  //                           emitCount({ uuid: vizItem.guid, vizItem: vizItem, x: 1, y: 0, z: 0 })}
  //                       >
  //                         <img src="/media/icon/noun-left-arrow-1035682-FFFFFF.svg" className="h-fit p-1 mx-auto " />
  //                       </div>
  //                       <div className="w-full h-5 "></div>
  //                       <div className="w-full h-5   hover:bg-zinc-900/50 rounded-sm cursor-pointer"
  //                         onClick={() =>
  //                           emitCount({ uuid: vizItem.guid, vizItem: vizItem, x: -1, y: 0, z: 0 })}
  //                       >
  //                         <img src="/media/icon/noun-right-arrow-1035676-FFFFFF.svg" className="h-fit p-1 mx-auto" />
  //                       </div>
  //                       <div className="w-full h-5  "></div>
  //                       <div className="w-full h-5  hover:bg-zinc-900/50 rounded-sm cursor-pointer"
  //                         onClick={() =>
  //                           emitCount({ uuid: vizItem.guid, vizItem: vizItem, x: 0, y: -1, z: 0 })}
  //                       >
  //                         <img src="/media/icon/noun-down-arrow-1035684-FFFFFF.svg" className="h-fit p-1 mx-auto " />
  //                       </div>
  //                       <div className="w-full h-5 "></div>
  //                     </div>
  //                   </div>
  //                   <div className="w-full h-14 bg-zinc-900/25 hover:bg-zinc-900/50  rounded-r-lg  cursor-pointer"
  //                     onClick={() =>
  //                       emitCount({ uuid: vizItem.guid, vizItem: vizItem, x: -3, y: 0, z: 0 })}
  //                   >
  //                     <img src="/media/icon/noun-right-arrow-1035676-FFFFFF.svg" className="h-8 mx-auto mt-3" />
  //                   </div>
  //                   <div className="w-full h-14  "></div>
  //                   <div className="w-full h-14 bg-zinc-900/25 hover:bg-zinc-900/50  rounded-b-lg  cursor-pointer mt-1"
  //                     onClick={() =>
  //                       emitCount({ uuid: vizItem.guid, vizItem: vizItem, x: 0, y: -3, z: 0 })}
  //                   >
  //                     <img src="/media/icon/noun-down-arrow-1035684-FFFFFF.svg" className="h-8 mx-auto mt-3" />
  //                   </div>
  //                   <div className="w-full h-14 "></div>
  //                 </div>
  //               </div>
  //             </div>
  //             : null}

  //         </Html>

  //       </Billboard>
  //     </group>
  //   )
  // }


  else if (htmlPreview == 1) {
    //console.log('HTML PREVIEW')
    return (


      <div
        className="mx-auto "
        style={{
          transform: 'scale(.5)',
          width: '150%',
          marginLeft: '-25%',
        }}
      >
        <DynamicContent vizType={vizType} style={{ scale: htmlPreviewScale }}
          key={vizItem.guid + 'dynamiccontent-preview'}
        ></DynamicContent>
      </div>

    )
  }
  else {
    return (null)
  }
}

export default ExpHTML;