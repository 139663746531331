import React, { useRef, useState, useCallback, useEffect } from 'react'
import { Canvas, useThree } from '@react-three/fiber'
import { OrbitControls, Box, Sphere, Cone, Torus, Html } from '@react-three/drei'
import * as THREE from 'three'

const DraggableMesh = ({ geometry, position, color, onDragStart, onDragEnd, htmlContent }) => {
  const meshRef = useRef()
  const materialRef = useRef()
  const [isDragging, setIsDragging] = useState(false)
  const { camera, gl, size } = useThree()
  const dragStart = useRef(new THREE.Vector2())
  const initialPosition = useRef(new THREE.Vector3())

  const getMousePosition = useCallback((event) => {
    const vec = new THREE.Vector3()
    const pos = new THREE.Vector3()

    vec.set(
      (event.clientX / size.width) * 2 - 1,
      -(event.clientY / size.height) * 2 + 1,
      0.5
    )

    vec.unproject(camera)
    vec.sub(camera.position).normalize()
    const distance = -camera.position.z / vec.z
    pos.copy(camera.position).add(vec.multiplyScalar(distance))

    return pos
  }, [camera, size])

  const onPointerDown = useCallback((event) => {
    event.stopPropagation()
    setIsDragging(true)
    onDragStart()
    gl.domElement.style.cursor = 'grabbing'
    const mousePos = getMousePosition(event)
    dragStart.current.set(mousePos.x, mousePos.y)
    initialPosition.current.copy(meshRef.current.position)
  }, [gl, getMousePosition, onDragStart])

  const onPointerMove = useCallback((event) => {
    if (!isDragging) return
    const currentPosition = getMousePosition(event)
    const dragDeltaX = currentPosition.x - dragStart.current.x
    const dragDeltaY = currentPosition.y - dragStart.current.y
    meshRef.current.position.x = initialPosition.current.x + dragDeltaX
    meshRef.current.position.y = initialPosition.current.y + dragDeltaY
  }, [isDragging, getMousePosition])

  const onPointerUp = useCallback(() => {
    setIsDragging(false)
    onDragEnd()
    gl.domElement.style.cursor = 'auto'
  }, [gl, onDragEnd])

  useEffect(() => {
    const domElement = gl.domElement
    domElement.addEventListener('pointermove', onPointerMove)
    domElement.addEventListener('pointerup', onPointerUp)
    return () => {
      domElement.removeEventListener('pointermove', onPointerMove)
      domElement.removeEventListener('pointerup', onPointerUp)
    }
  }, [gl, onPointerMove, onPointerUp])

  useEffect(() => {
    //if (materialRef.current) {
    //  materialRef.current.color.set(isDragging ? '#ff0000' : color)
    //}
    console.log('dragging', isDragging)
  }, [isDragging, color])

  return (
    <mesh
      ref={meshRef}
      position={position}
      onPointerDown={onPointerDown}
    >
      {geometry}
      <meshStandardMaterial ref={materialRef} 
      //color={color} 
      //color={isDragging ? 'red' : '#333'}
      color='red'
      />
      <Html
        position={[0, 0, -0.51]}
        center
        style={{
          background: 'rgba(0, 0, 0, 0.5)',
          padding: '10px',
          color: 'white',
          borderRadius: '5px',
          fontSize: '12px',
          width: '200px',
          pointerEvents: 'none'
        }}
      >
        {htmlContent}
      </Html>
    </mesh>
  )
}

const Scene = () => {
  const [isDragging, setIsDragging] = useState(false)
  const controlsRef = useRef()

  const handleDragStart = useCallback(() => {
    setIsDragging(true)
  }, [])

  const handleDragEnd = useCallback(() => {
    setIsDragging(false)
  }, [])

  return (
    <Canvas style={{ width: '100%', height: '100vh' }} camera={{ position: [0, 0, 10], fov: 50 }}>
      <OrbitControls
        ref={controlsRef}
        enabled={!isDragging}
      />
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <DraggableMesh 
        geometry={<Box args={[1, 1, 1]} />} 
        position={[-3, 2, 0]} 
        color="red" 
        onDragStart={handleDragStart} 
        onDragEnd={handleDragEnd}
        htmlContent={<div>Box</div>}
      />
      <DraggableMesh 
        geometry={<Sphere args={[0.5, 32, 32]} />} 
        position={[-1, -2, 0]} 
        color="blue" 
        onDragStart={handleDragStart} 
        onDragEnd={handleDragEnd}
        htmlContent={<div>Sphere Sphere Sphere Sphere Sphere <br/>Sphere Sphere Sphere Sphere Sphere <br/>Sphere Sphere Sphere Sphere Sphere</div>}
      />
      <DraggableMesh 
        geometry={<Cone args={[0.5, 1, 32]} />} 
        position={[1, 2, 0]} 
        color="green" 
        onDragStart={handleDragStart} 
        onDragEnd={handleDragEnd}
        htmlContent={<div>Cone</div>}
      />
      <DraggableMesh 
        geometry={<Torus args={[0.3, 0.2, 16, 100]} />} 
        position={[3, -2, 0]} 
        color="yellow" 
        onDragStart={handleDragStart} 
        onDragEnd={handleDragEnd}
        htmlContent={<div>Torus</div>}
      />
    </Canvas>
  )
}

export default Scene