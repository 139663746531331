import { Grid, useAspect, useVideoTexture, shaderMaterial, Environment, Html, CurveModifier, Float, Text, Decal, useTexture, Backdrop } from '@react-three/drei'
import * as THREE from 'three'
import React, { useRef, useContext, useMemo, useEffect, useState, Suspense } from 'react'
import { useFrame, extend, useThree, useLoader } from '@react-three/fiber'
import gsap from "gsap";
import { SessionContext, SceneContext, StoryContext } from './AppStoryPlayer'
import { v4 } from 'uuid'

// Experience Components 
import ExpHTML from './experience/ExpHTML'
import ExpModel from './experience/ExpModel'
import ExpLine from './experience/ExpLine';
import { ExpModelMatcap } from './experience/ExpModelMatcap';
import { GetContentPosition } from './components/UtilFunctions';

//export default function AppStoryExperience(props) {

const AppStoryExperience = (props) => {

  //  const [sessionData, setSessionData] = useContext(SessionContext)
  const [sceneData, setSceneData] = useContext(SceneContext)
  const [storyData, setStoryData] = useContext(StoryContext)

  /* >> VIDEO TESTING */
  const { DEG2RAD } = THREE.MathUtils
  const horizontalSpace = 40

  const RenderScenePlatforms = () => {

    return sceneData.map((item, index) =>
      <group key={item.id + 'platform'}>
        {item.platform ?
          <>
            {item.platform.model != 'platform_empty' ?
              <>




                {item.platform.model != 'platform_placeholder' ?
                  <group>
                    <ExpModelMatcap
                      //model='platform_flow_right'
                      model={item.platform.model}
                      //matcap='4F4F4F_9C9C9C_121212_7C7C7C'
                      matcap={item.platform.model == 'platform_placeholder' ? 'MC-Sample5' : item.platform.matcap}
                      //matcap='3F4441_D1D7D6_888F87_A2ADA1'
                      //position={[(index * horizontalSpace) - 50, 0, 0]}
                      position={GetContentPosition(storyData, sceneData, index)}
                      key={item.id + 'platform'}
                    />

                    <Text
                      color={item.platform.text.color || 'white'}
                      //fontWeight={item.platform.text.fontWeight || 'bold'}
                      // style={{
                      //   fontSize: 3,
                      //   fontWeight: 700, // This sets the font weight
                      // }}
                      //color={'#000'} 
                      //position={GetContentPosition(storyData, sceneData, index, 0, -1.5, -13.2)}
                      //position={GetContentPosition(storyData, sceneData, index, 0, -1.5, -13.2)}
                      position={GetContentPosition(storyData, sceneData, index, 0, -2, -13.2)}
                      //scale={item.platform.text.scale || 1.2}>
                      strokeColor={item.platform.text.color}
                      strokeWidth={5}
                      scale={1.25}
                      >
                      {item.name}
                    </Text>
                  </group>
                  : null}


                <Text 
              color="white" 
              position={[(index * horizontalSpace) - 50, -3.5, 13.2]} 
              scale={.75} maxWidth={24}>
              {item.description}
            </Text>  
              </>
              :
              null
            }
          </>
          :
          <>
            <ExpModelMatcap
              //model='platform_flow_right'
              model='platform_a_cap'
              //matcap='4F4F4F_9C9C9C_121212_7C7C7C'
              matcap='434240_D3D3CF_898784_A4A49F_mod4'
              //matcap='3F4441_D1D7D6_888F87_A2ADA1'
              //position={[(index * horizontalSpace) - 50, 0, 0]}
              position={GetContentPosition(storyData, sceneData, index)}
              key={item.id + 'platform'}
            />
            <Text
              color="white"
              position={[(index * horizontalSpace) - 50, -1.5, 13.2]}
              scale={1.5}>
              {item.name}
            </Text>
            {/* <Text 
              color="white" 
              position={[(index * horizontalSpace) - 50, -3.5, 13.2]} 
              scale={1.5}>
              {item.description}
            </Text>             */}
          </>
        }
      </group>
    )
  }

  const RenderScenePersonas = () => {


    return sceneData.map((item, index) =>

      <group key={item.guid + 'vizgroup'}>
        {/* <ExpModel
          modelPath={'/components/platform-a.glb'}
          scale={1}
          position={[(index * horizontalSpace) - 50, 0, 0]}
          key={item.id + 'platform'}
        /> */}

        {/* <ExpModelMatcap
          model='platform_b_cap'
          matcap='4F4F4F_9C9C9C_121212_7C7C7C'
          position={[(index * horizontalSpace) - 50, 0, 0]}
          key={item.id + 'platform'}
        /> */}

        {item.viznodes.map((vizItem) =>
          <group key={vizItem.guid + 'vizitem'}>
            { // Persona
              vizItem.id == '0' || vizItem.id == 16 ?
                <ExpHTML
                  thisIndex={index}
                  //position={[(index * horizontalSpace - vizItem.pos.x) - 50, 18 - vizItem.pos.y, 0]}
                  position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, 0)}
                  //position={[vizItem.pos.x, vizItem.pos.y, 0]}
                  vizType={vizItem.id}
                  vizItem={vizItem}
                  key={vizItem.guid}
                  storyData={storyData}
                  setOrbitActive={props.setOrbitActive}
                />
                : (null)
            }
            { // Persona
              vizItem.id == '1' ?
                <ExpHTML
                  thisIndex={index}
                  //position={[(index * horizontalSpace - vizItem.pos.x) - 50, 18 - vizItem.pos.y, 0]}
                  position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, 0)}
                  vizType={1}
                  vizItem={vizItem}
                  key={vizItem.guid}
                  setOrbitActive={props.setOrbitActive}
                />
                : (null)
            }
            { // Device
              vizItem.id == '2' ?
                <ExpHTML
                  thisIndex={index}
                  key={vizItem.guid}
                  //position={[(index * horizontalSpace - vizItem.pos.x) - 50, 22 - vizItem.pos.y, 0]}
                  position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, 0)}
                  vizType={2}
                  vizItem={vizItem}
                  setOrbitActive={props.setOrbitActive}
                />
                : (null)
            }
            {  vizItem.id == '3' & vizItem.matcap == undefined ?
                <ExpModel
                  vizItem={vizItem}
                  modelPath={vizItem.model.includes('storage.googleapis.com') ? vizItem.model : '/components/' + vizItem.model}
                  //modelPath={vizItem.model}
                  scale={vizItem.scale || 1}
                  setOrbitActive={props.setOrbitActive}
                  rotation={vizItem.rot ? [vizItem.rot.x, vizItem.rot.y, vizItem.rot.z] : [0, 0, 0]}
                  position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, vizItem.pos.z || 0)}
                  key={vizItem.guid} />
                : (null)
            }
            { // 3D Model - Matcap
              vizItem.id == '3' & vizItem.matcap != undefined ? // console.log(vizItem)
                <ExpModelMatcap
                  model={vizItem.model.replace('.glb', '')}
                  matcap={vizItem.model.includes('mini') ? storyData.backgroundMatcap : vizItem.matcap}
                  //position={[(index * horizontalSpace) - 50, 0, 0]}
                  position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, vizItem.pos.z || 0)}
                  key={vizItem.guid + 'matcap'}
                  animation={vizItem.animation}
                  scale={vizItem.scale || 1}
                />
                : (null)
            }

            { // Raw image
              vizItem.id == '4' ?
                <ExpHTML
                  thisIndex={index}
                  key={vizItem.guid}
                  //position={[(index * horizontalSpace - vizItem.pos.x) - 50, 18 - vizItem.pos.y, 0]}
                  position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, 0)}
                  vizItem={vizItem}
                  vizType={4} // TODO - update here...!
                  setOrbitActive={props.setOrbitActive}
                />
                : (null)
            }
            { // Link external
              vizItem.id == '5' ?
                <ExpHTML
                  thisIndex={index}
                  key={vizItem.guid}
                  //position={[(index * horizontalSpace - vizItem.pos.x) - 50, 18 - vizItem.pos.y, 0]}
                  position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, 0)}
                  vizItem={vizItem}
                  vizType={5} // TODO - update here...!
                  setOrbitActive={props.setOrbitActive}
                />
                : (null)
            }
            { // Web embed
              vizItem.id == '6' ?
                <ExpHTML
                  thisIndex={index}
                  key={vizItem.guid}
                  //position={[(index * horizontalSpace) - 50, 22, 0]}
                  position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, 0)}
                  vizItem={vizItem}
                  vizType={6}
                  setOrbitActive={props.setOrbitActive}
                />
                : (null)
            }
            { // Web embed
              vizItem.id == '7' ?
                <ExpHTML
                  thisIndex={index}
                  key={vizItem.guid}
                  //position={[(index * horizontalSpace) - 50, 22, 0]}
                  position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, 0)}
                  vizItem={vizItem}
                  vizType={7}
                  setOrbitActive={props.setOrbitActive}
                />
                : (null)
            }
            {/* { // Ecosystem / Org Chart
              vizItem.id == '9' || vizItem.id == '10' ?
                <ExpHTML
                  thisIndex={index}
                  key={vizItem.guid}
                  position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, vizItem.pos.z)}
                  vizItem={vizItem}
                  vizType={vizItem.id}
                />
                : (null)
            }             */}
            { // Raw image
              vizItem.id == '14' ?
                <ExpHTML
                  thisIndex={index}
                  key={vizItem.guid}
                  //position={[(index * horizontalSpace - vizItem.pos.x) - 50, 18 - vizItem.pos.y, 0]}
                  position={GetContentPosition(storyData, sceneData, index, vizItem.pos.x, vizItem.pos.y, vizItem.pos.z)}
                  vizItem={vizItem}
                  vizType={vizItem.id} // TODO - update here...!
                  setOrbitActive={props.setOrbitActive}
                />
                : (null)
            }
          </group>
        )}

        {//sessionData.modeVideo != 'newscaster' & 
          storyData.backgroundHeight != null ?
            <ExpModelMatcap
              model={storyData.backgroundModel}
              matcap={storyData.backgroundMatcap}
              position={[0, storyData.backgroundHeight, 0]}
              key={'main-platform'}
            />
            : (null)}

      </group>
    )
  }

  const [storyId, setStoryId] = useState('')

  useEffect(() => {
    setStoryId(storyData.id)
  }, [storyData])

  return React.useMemo(() => {
    return (
      <>
        <ambientLight intensity={1} />
        <RenderScenePersonas></RenderScenePersonas>
        <RenderScenePlatforms></RenderScenePlatforms>
      </>
    )
  }, [storyData, sceneData])
}
//}

export default AppStoryExperience;