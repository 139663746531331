import { createEvent } from "react-event-hook";

const { usePingListener, emitPing } = createEvent("ping")();
const { usePongListener, emitPong } = createEvent("pong")();

export const {useVidListener, emitVid} = createEvent("vid")(["progress", "length", "stop", "start", "seek"])

export const { useCountListener, emitCount} = createEvent("count")(["increment", "decrement"]);

export const {useOnChangeListener, emitOnChange} = createEvent("onChange")(["id"])


// NEW for move...!
export const { useMovePosListener, emitMovePos} = createEvent("move")(["increment", "decrement"]);


//export const { useVideoPlayerListner, emitVideoPlayer } = createEvent("videoPlayer")(["progress"])
//const {useVideoPlayerListner, emitVideoPlayer} = createEvent("videoPlayer")(["progress"])
// Video player
//export const { useVideoPlayerListner, emitVideoPlayer } = createEvent("videoPlayer")(["progress"])
//export const { useVideoPlayer, emitVideoPlayer } = createEvent("videoPlayer")(["play", "pause", "stop", "progress", "seek", "volume", "mute", "unmute"])
//(["play", "pause", "stop", "progress", "seek", "volume", "mute", "unmute"])
//export { useVizNudge, emitVizNudge }